<template>
  <b-modal
    id="customPlanModal"
    :title="$t('modals.customPlan.customizedPlan')"
    hide-footer
    modal-class="px-0"
    body-class="pt-0"
    no-close-on-backdrop
  >
    <!-- Form -->
    <b-form role="form" @submit.prevent="onSubmit">
      <!-- Tweet Limit -->
      <b-row class="mt-3">
        <b-col cols="12">
          <span class="range-slider-value">
            {{ `${$t("modals.customPlan.maxTweetLimit")}: ${parseInt(tweetLimit)}` }}
          </span>
        </b-col>
        <b-col cols="12">
          <base-slider v-model="tweetLimit" :options="{ step: 10 }" :range="{ min: 50, max: 500 }"></base-slider>
        </b-col>
      </b-row>

      <!-- Favorite Limit -->
      <b-row class="mt-3">
        <b-col cols="12">
          <span class="range-slider-value">
            {{ `${$t("modals.customPlan.maxFavoriteLimit")}: ${parseInt(favoriteLimit)}` }}
          </span>
        </b-col>
        <b-col cols="12">
          <base-slider v-model="favoriteLimit" :options="{ step: 10 }" :range="{ min: 50, max: 500 }"></base-slider>
        </b-col>
      </b-row>

      <!-- User Limit -->
      <b-row class="mt-3">
        <b-col cols="12">
          <span class="range-slider-value">
            {{ `${$t("modals.customPlan.maxUserCount")}: ${parseInt(userCount)}` }}
          </span>
        </b-col>
        <b-col cols="12">
          <base-slider v-model="userCount" :options="{ step: 1 }" :range="{ min: 2, max: 20 }"></base-slider>
        </b-col>
      </b-row>

      <!-- Price -->
      <div class="mt-3 display-5">
        {{ `${$t("modals.customPlan.totalPrice")}:` }}
        <strong v-if="$i18n.locale == 'tr'">{{ this.totalPrice.toFixed(2).replace(".", ",") }}₺</strong>
        <strong v-else>${{ this.totalPrice.toFixed(2).replace(".", ",") }}</strong>
      </div>
      <small class="text-muted">
        {{ $t("modals.customPlan.priceInfo") }}
      </small>

      <!-- Button -->
      <div v-if="signedIn" class="text-center">
        <base-button v-if="hasPlan" block type="primary" class="mt-4 mb-2" @click.prevent="switchPlan">
          {{ $t("modals.customPlan.switchButton") }}
        </base-button>
        <base-button v-else block type="primary" class="mt-4 mb-2" @click.prevent="purchasePlan">
          {{ $t("modals.customPlan.purchaseButton") }}
        </base-button>
      </div>

      <div v-else class="text-center">
        <base-button block type="primary" @click.prevent="$router.push({ name: 'signIn' })" class="mt-4 mb-2">
          {{ $t("modals.customPlan.signInButton") }}
        </base-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import BaseSlider from "../../components/BaseSlider.vue";
import { mapGetters, mapState } from "vuex";

export default {
  props: ["planId"],
  components: {
    BaseSlider,
  },
  computed: {
    ...mapGetters("auth", ["signedIn"]),
    ...mapState("user", ["hasPlan"]),
    packagePrice() {
      return (
        // eslint-disable-next-line prettier/prettier
        (this.tweetLimit * this.tweetMultiplier) * (this.favoriteLimit * this.favoriteMultiplier) * (this.userCount * this.userMultiplier)
      );
    },
    totalPrice() {
      return (this.packagePrice + this.fixedCommission) / (1 - this.commissionPercent);
    },
  },
  data: () => ({
    tweetLimit: 50,
    favoriteLimit: 50,
    userCount: 2,
    favoriteMultiplier: 1,
    tweetMultiplier: 0.02,
    userMultiplier: 0.5,
    fixedCommission: 2,
    commissionPercent: 2.9 / 100,
  }),
  methods: {
    async createCustomPrice() {
      const response = await this.$store.dispatch("subscription/createCustomPrice", {
        planId: this.planId,
        maxCurrentPlanUser: parseInt(this.userCount),
        favLimit: parseInt(this.favoriteLimit),
        tweetLimit: parseInt(this.tweetLimit),
      });
      if (response) return response.data.priceId;
      else return null;
    },
    async purchasePlan() {
      let vm = this;
      // Ask For Plan Purchase Confirmation
      swal
        .fire({
          title: vm.$t("modals.customPlan.purchaseConfirmTitle"),
          html: vm.$t("modals.customPlan.purchaseConfirmText", {
            price: this.totalPrice.toFixed(2).replace(".", ","),
          }),
          padding: "0.8rem",
          width: "40rem",
          focusCancel: true,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#3c4d69",
          confirmButtonText: vm.$t("modals.customPlan.confirmButton"),
          cancelButtonText: vm.$t("modals.customPlan.cancelButton"),
        })
        // If Confirmed, Send Request and Show Loading Alert
        .then(async (result) => {
          if (result.isConfirmed) {
            vm.$bvModal.hide("customPlanModal");
            swal.fire({
              title: "<i class='fas fa-spinner fa-spin' style='font-size: 2.5rem; margin-top:2rem;'></i>",
              html: vm.$t("modals.customPlan.redirectingNotification"),
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onBeforeOpen: async function () {
                const priceId = await vm.createCustomPrice();
                if (priceId) {
                  const response = await vm.$store.dispatch("subscription/checkout", { priceId: priceId });
                  // Redirect to Checkout Page
                  if (response) window.location = response.data.url;
                  else swal.close();
                }
              },
            });
          }
        });
    },
    async switchPlan() {
      let vm = this;
      // Ask For Plan Change Confirmation
      swal
        .fire({
          title: vm.$t("modals.customPlan.switchConfirmTitle"),
          html: vm.$t("modals.customPlan.switchConfirmText", { price: vm.totalPrice.toFixed(2).replace(".", ",") }),
          padding: "0.8rem",
          width: "40rem",
          focusCancel: true,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#3c4d69",
          confirmButtonText: vm.$t("modals.customPlan.confirmButton"),
          cancelButtonText: vm.$t("modals.customPlan.cancelButton"),
        })
        // If Confirmed, Send Request and Show Loading Alert
        .then(async (result) => {
          if (result.isConfirmed) {
            vm.$bvModal.hide("customPlanModal");
            swal.fire({
              title: "<i class='fas fa-spinner fa-spin' style='font-size: 2.5rem; margin-top:2rem;'></i>",
              html: vm.$t("modals.customPlan.switchingNotification"),
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onBeforeOpen: async function () {
                const priceId = await vm.createCustomPrice();
                if (priceId) {
                  const response = await vm.$store.dispatch("subscription/upgrade", { priceId: priceId });
                  swal.close();
                  // Show Success Alert If Plan Updated
                  if (response) {
                    vm.$store.dispatch("currentPlan/setCurrentPlan", response.data.currentPlan);
                    swal.fire({
                      title: vm.$t("modals.customPlan.switchSuccess"),
                      showConfirmButton: false,
                      icon: "success",
                      timer: 3000,
                      timerProgressBar: true,
                      onDestroy: () => {
                        let element = document.getElementById("subscriptionNotification");
                        element.scrollIntoView({ behavior: "smooth", block: "center" });
                      },
                    });
                  }
                }
              },
            });
          }
        });
    },
  },
};
</script>

<style></style>
