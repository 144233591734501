<template>
  <b-row>
    <!-- Content -->
    <template v-if="!showLoader">
      <!-- Show If User Does Not Have A Plan -->
      <template v-if="showEmpty">
        <!-- Subscription Status -->
        <b-col md="6" xl="3">
          <stats-card
            class="subscription-stats-card"
            :title="$t('widgets.subscriptionDetails.currentPlan')"
            type="gradient-info"
            sub-title="-"
            icon="fas fa-tag"
            icon-classes="icon-sm"
          >
          </stats-card>
        </b-col>
        <!-- User Count -->
        <b-col md="6" xl="3">
          <stats-card
            class="subscription-stats-card"
            :title="$t('widgets.subscriptionDetails.startDate')"
            type="gradient-success"
            sub-title="-"
            icon="fas fa-hourglass-start"
            icon-classes="icon-sm"
          >
          </stats-card>
        </b-col>
        <!-- Favorite Limit -->
        <b-col md="6" xl="3">
          <stats-card
            class="subscription-stats-card"
            :title="$t('widgets.subscriptionDetails.renewDate')"
            type="gradient-warning"
            sub-title="-"
            icon="fas fa-hourglass-end"
            icon-classes="icon-sm"
          >
          </stats-card>
        </b-col>
        <!-- Tweet Limit -->
        <b-col md="6" xl="3">
          <stats-card
            class="subscription-stats-card"
            :title="$t('widgets.subscriptionDetails.renewPrice')"
            type="gradient-danger"
            sub-title="-"
            icon="fas fa-dollar-sign"
            icon-classes="icon-sm"
          >
          </stats-card>
        </b-col>
      </template>
      <!-- Show If User Has A Plan -->
      <template v-else>
        <!-- Subscription Status -->
        <b-col md="6" xl="3">
          <stats-card
            class="subscription-stats-card"
            :title="$t('widgets.subscriptionDetails.currentPlan')"
            type="gradient-info"
            :sub-title="plans.filter((plan) => currentPlan.planId == plan.id)[0].name"
            icon="fas fa-tag"
            icon-classes="icon-sm"
          >
          </stats-card>
        </b-col>
        <!-- Start Date -->
        <b-col md="6" xl="3">
          <stats-card
            class="subscription-stats-card"
            :title="$t('widgets.subscriptionDetails.startDate')"
            type="gradient-success"
            :sub-title="$d(new Date(parseInt(currentPlan.createdDate)), 'short')"
            icon="fas fa-hourglass-start"
            icon-classes="icon-sm"
          >
          </stats-card>
        </b-col>
        <!-- Renew Date -->
        <b-col md="6" xl="3">
          <stats-card
            class="subscription-stats-card"
            :title="$t('widgets.subscriptionDetails.renewDate')"
            type="gradient-warning"
            :sub-title="$d(new Date(parseInt(currentPlan.expiryDate)), 'short')"
            icon="fas fa-hourglass-end"
            icon-classes="icon-sm"
          >
          </stats-card>
        </b-col>
        <!-- Renew Price -->
        <b-col md="6" xl="3">
          <stats-card
            class="subscription-stats-card"
            :title="$t('widgets.subscriptionDetails.renewPrice')"
            type="gradient-danger"
            :sub-title="
              $t('widgets.subscriptionDetails.renewPriceCurrency', {
                price: (currentPlan.price / 100).toFixed(2).replace('.', ','),
              })
            "
            icon="fas fa-dollar-sign"
            icon-classes="icon-sm"
          >
          </stats-card>
        </b-col>
      </template>
    </template>
    <!-- Placeholder -->
    <template v-else>
      <b-col md="6" xl="3">
        <b-card no-body>
          <b-card-body class="details-placeholder-card">
            <ContentLoader height="98" width="380" secondaryColor="#b8b8b8">
              <circle cx="335" cy="35" r="15" />
              <rect x="20" y="25" rx="2" ry="2" width="190" height="11" />
              <rect x="20" y="45" rx="2" ry="2" width="110" height="11" />
            </ContentLoader>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6" xl="3">
        <b-card no-body>
          <b-card-body class="details-placeholder-card">
            <ContentLoader height="98" width="380" secondaryColor="#b8b8b8">
              <circle cx="335" cy="35" r="15" />
              <rect x="20" y="25" rx="2" ry="2" width="190" height="11" />
              <rect x="20" y="45" rx="2" ry="2" width="110" height="11" />
            </ContentLoader>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6" xl="3">
        <b-card no-body>
          <b-card-body class="details-placeholder-card">
            <ContentLoader height="98" width="380" secondaryColor="#b8b8b8">
              <circle cx="335" cy="35" r="15" />
              <rect x="20" y="25" rx="2" ry="2" width="190" height="11" />
              <rect x="20" y="45" rx="2" ry="2" width="110" height="11" />
            </ContentLoader>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6" xl="3">
        <b-card no-body>
          <b-card-body class="details-placeholder-card">
            <ContentLoader height="98" width="380" secondaryColor="#b8b8b8">
              <circle cx="335" cy="35" r="15" />
              <rect x="20" y="25" rx="2" ry="2" width="190" height="11" />
              <rect x="20" y="45" rx="2" ry="2" width="110" height="11" />
            </ContentLoader>
          </b-card-body>
        </b-card>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import StatsCard from "@/components/Cards/StatsCard";
import { mapState } from "vuex";
import { ContentLoader } from "vue-content-loader";
export default {
  props: ["plans"],
  components: {
    StatsCard,
    ContentLoader,
  },
  computed: {
    ...mapState("currentPlan", ["currentPlan"]),
    ...mapState("user", ["hasPlan"]),
    showLoader() {
      if (this.plans == null || this.hasPlan == null) {
        return true;
      } else {
        if (this.hasPlan && this.currentPlan == null) return true;
        else return false;
      }
    },
    showEmpty() {
      if (!this.hasPlan) return true;
      else return false;
    },
  },
};
</script>

<style>
.details-placeholder-card {
  padding: 0px !important;
}

.subscription-stats-card {
  min-height: 0px !important;
}
</style>
