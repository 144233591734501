<template>
  <b-modal
    id="editUserModal"
    :title="$t('modals.editUser.editUser')"
    hide-footer
    modal-class="px-0"
    body-class="pt-0"
    no-close-on-backdrop
  >
    <!-- Edit Unavailable Alert -->
    <b-alert :show="!isActive && !updatingStatus" variant="danger">
      <span>
        <i class="fas fa-exclamation-circle"></i>
        <strong> &nbsp;{{ $t("modals.editUser.editingUnavailable") }} </strong>
      </span>
    </b-alert>
    <!-- Updating Status Alert -->
    <b-alert :show="updatingStatus" variant="info">
      <span>
        <i class="fas fa-spinner fa-spin"></i>
        <strong> &nbsp;{{ $t("modals.editUser.updatingDeliveryStatus") }} </strong>
      </span>
    </b-alert>
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
      <b-form role="form" @submit.prevent="handleSubmit(updateUser)">
        <!-- Delivery Status -->
        <base-input>
          <div class="d-flex align-content-center">
            <span>{{ $t("modals.editUser.deliveryStatus") }}:&nbsp;</span>
            <base-switch
              @input="updateStatus()"
              :disabled="updatingStatus || deletingUser || updatingUser"
              on-text=""
              off-text=""
              v-model="isActive"
            ></base-switch>
          </div>
        </base-input>

        <!-- Username -->
        <base-input
          :disabled="true"
          name="username"
          v-model="username"
          :rules="{
            required: true,
            regex: /^[a-zA-Z_0-9]+(?:[a-zA-Z_0-9]+)*$/,
          }"
          prepend-icon="fas fa-at"
          :placeholder="$t('modals.editUser.username')"
        >
        </base-input>

        <!-- Include Retweets-->
        <base-checkbox
          :disabled="!isActive || updatingStatus || deletingUser || updatingUser"
          class="mb-1"
          name="includeRetweets"
          v-model="includeRetweets"
        >
          {{ $t("modals.editUser.includeRetweets") }}
          <i class="fas fa-info-circle" v-b-tooltip.hover.bottom :title="$t('modals.tips.includeRetweets')"></i>
        </base-checkbox>

        <!-- Include Comments-->
        <base-checkbox
          :disabled="!isActive || updatingStatus || deletingUser || updatingUser"
          class="mb-1"
          name="includeComments"
          v-model="includeComments"
        >
          {{ $t("modals.editUser.includeComments") }}
          <i class="fas fa-info-circle" v-b-tooltip.hover.bottom :title="$t('modals.tips.includeComments')"></i>
        </base-checkbox>

        <!-- Natural Delivery-->
        <base-checkbox
          :disabled="!isActive || updatingStatus || deletingUser || updatingUser"
          class="mb-1"
          name="naturalDelivery"
          v-model="naturalDelivery"
        >
          {{ $t("modals.editUser.naturalDelivery") }}
          <i class="fas fa-info-circle" v-b-tooltip.hover.bottom :title="$t('modals.tips.naturalDelivery')"></i>
        </base-checkbox>

        <!-- Delayed Delivery-->
        <div class="mb-3">
          <base-checkbox
            :disabled="!isActive || updatingStatus || deletingUser || updatingUser"
            class="mb-2"
            name="delayedDelivery"
            v-model="delayedDelivery"
          >
            {{ $t("modals.editUser.delayedDelivery") }}
            <i class="fas fa-info-circle" v-b-tooltip.hover.bottom :title="$t('modals.tips.delayedDelivery')"></i>
          </base-checkbox>
          <base-input
            v-if="delayedDelivery"
            :disabled="!isActive || updatingStatus || deletingUser || updatingUser"
            name="delayTime"
            v-model="delayTime"
            :rules="{
              required: delayedDelivery ? true : false,
              regex: /^[-+]?[1-9]\d*$/,
            }"
            prepend-icon="fas fa-stopwatch"
            :placeholder="$t('modals.editUser.delayTime')"
          >
            <template slot="append">{{ $t("modals.editUser.seconds") }}</template>
          </base-input>
        </div>

        <!-- Reaction Type-->
        <base-input>
          <template slot="label">
            {{ $t("modals.editUser.reactionType") }}
            <i class="fas fa-info-circle" v-b-tooltip.hover.bottom :title="$t('modals.tips.reactionType')"></i>
          </template>
          <el-select
            :disabled="!isActive || updatingStatus || deletingUser || updatingUser"
            v-model="reactionType"
            :placeholder="$t('modals.editUser.reactionType')"
          >
            <el-option
              v-for="reaction in reactionTypes"
              :key="reaction.value"
              :label="reaction.label"
              :value="reaction.value"
            >
            </el-option>
          </el-select>
        </base-input>

        <!-- Tweet Limit -->
        <b-row class="mt-3">
          <b-col cols="12">
            <span class="range-slider-value">
              {{ `${$t("modals.editUser.tweetLimit")}: ${parseInt(tweetLimit)}` }}
            </span>
          </b-col>
          <b-col cols="12">
            <base-slider
              :disabled="!isActive || updatingStatus || deletingUser || updatingUser"
              v-model="tweetLimit"
              :options="{ step: 1 }"
              :range="{
                min: currentUser.processedTweet,
                max: currentPlan.availableTweet + currentUser.remainTweet,
              }"
            ></base-slider>
          </b-col>
        </b-row>

        <!-- Favorite Limit -->
        <b-row class="mt-3">
          <b-col cols="12">
            <span class="range-slider-value">
              {{ `${$t("modals.editUser.favoriteLimit")}: ${parseInt(favoriteLimit)}` }}
            </span>
          </b-col>
          <b-col cols="12">
            <base-slider
              :disabled="!isActive || updatingStatus || deletingUser || updatingUser"
              v-model="favoriteLimit"
              :options="{ step: 1 }"
              :range="{
                min: 1,
                max: currentPlan.availableFav + currentUser.favLimit,
              }"
            ></base-slider>
          </b-col>
        </b-row>

        <!-- Buttons -->
        <div class="text-center">
          <!-- Update Button -->
          <base-button
            :disabled="!isActive || updatingStatus || deletingUser || updatingUser"
            :loading="updatingUser"
            block
            type="primary"
            native-type="submit"
            class="mt-4"
          >
            {{ $t("modals.editUser.editUserButton") }}
          </base-button>
          <!-- Delete Button -->
          <base-button
            :disabled="updatingStatus || deletingUser || updatingUser"
            :loading="deletingUser"
            block
            type="danger"
            @click.prevent="deleteUser()"
          >
            {{ $t("modals.editUser.deleteUserButton") }}
          </base-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import BaseSlider from "../../components/BaseSlider.vue";
import { Select, Option } from "element-ui";
import { mapGetters, mapState } from "vuex";
import BaseSwitch from "../../components/BaseSwitch.vue";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseSlider,
    BaseSwitch,
  },
  computed: {
    ...mapState("currentPlan", ["currentPlan"]),
    ...mapGetters("currentPlan", ["currentUser"]),
    reactionTypes() {
      return [
        {
          label: this.$t("reactionTypes.random"),
          value: 1,
        },
        {
          label: this.$t("reactionTypes.hmm"),
          value: 2,
        },
        {
          label: this.$t("reactionTypes.sad"),
          value: 3,
        },
        {
          label: this.$t("reactionTypes.haha"),
          value: 4,
        },
        {
          label: this.$t("reactionTypes.cheer"),
          value: 5,
        },
        {
          label: this.$t("reactionTypes.like"),
          value: 6,
        },
      ];
    },
  },
  data: () => ({
    updatingStatus: false,
    deletingUser: false,
    updatingUser: false,
    id: "",
    username: "",
    isActive: true,
    includeRetweets: false,
    includeComments: false,
    naturalDelivery: false,
    delayedDelivery: false,
    delayTime: "",
    reactionType: 1,
    tweetLimit: 1,
    favoriteLimit: 1,
    remainTweet: 1,
  }),
  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId == "editUserModal") {
        if (this.currentUser) {
          this.id = this.currentUser.id;
          this.username = this.currentUser.username;
          this.isActive = this.currentUser.isActive;
          this.includeRetweets = this.currentUser.includeRetweets;
          this.includeComments = this.currentUser.includeComments;
          this.naturalDelivery = this.currentUser.naturalSend;
          this.delayedDelivery = this.currentUser.delay > 0 ? true : false;
          this.delayTime = this.currentUser.delay > 0 ? this.currentUser.delay : "";
          this.reactionType = this.currentUser.reactionType;
          this.tweetLimit = this.currentUser.tweetLimit;
          this.favoriteLimit = this.currentUser.favLimit;
          this.remainTweet = this.currentUser.remainTweet;
        }
      }
    });
  },
  methods: {
    scrollToTop() {
      let detailsElement = document.getElementsByClassName("currentUserDetailsScrollArea")[0];
      detailsElement.scrollTop = 0;
      setTimeout(() => {
        detailsElement.classList.toggle("ps--active-y", false);
      }, 100);
      let usagesElement = document.getElementsByClassName("currentUserUsagesScrollArea")[0];
      usagesElement.scrollTop = 0;
      setTimeout(() => {
        usagesElement.classList.toggle("ps--active-y", false);
      }, 100);
      let usersElement = document.getElementsByClassName("currentPlanUsersScrollArea")[0];
      usersElement.scrollTop = 0;
      setTimeout(() => {
        usersElement.classList.toggle("ps--active-y", false);
      }, 100);
    },
    async updateStatus() {
      this.updatingStatus = true;
      const response = await this.$store.dispatch("currentPlanUser/updateStatus", {
        id: this.id,
      });
      this.updatingStatus = false;
      if (!response) this.isActive = this.currentUser.isActive;
    },
    async deleteUser() {
      swal
        .fire({
          title: `<strong>${this.$t("modals.editUser.confirmDeleteTitle")}</strong>`,
          html: `<p class="text-muted">${this.$t("modals.editUser.confirmDeleteText")}</p>`,
          padding: "0.8rem",
          width: "40rem",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#3c4d69",
          confirmButtonText: `${this.$t("modals.editUser.confirmButton")}`,
          cancelButtonText: `${this.$t("modals.editUser.cancelButton")}`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.deletingUser = true;
            const response = await this.$store.dispatch("currentPlanUser/deleteUser", this.id);
            this.deletingUser = false;
            if (response) {
              this.scrollToTop();
              this.$bvModal.hide("editUserModal");
              swal.fire({
                title: `<strong>${this.$t("modals.editUser.deleteSuccess")}</strong>`,
                showConfirmButton: false,
                icon: "success",
                timer: 3000,
                timerProgressBar: true,
              });
            }
          }
        });
    },
    async updateUser() {
      this.updatingUser = true;
      const response = await this.$store.dispatch("currentPlanUser/updateUser", {
        id: this.id,
        username: this.username,
        includeRetweets: this.includeRetweets,
        includeComments: this.includeComments,
        naturalSend: this.naturalDelivery,
        delay: this.delayedDelivery ? parseInt(this.delayTime) : 0,
        reactionType: this.reactionType,
        favLimit: parseInt(this.favoriteLimit),
        tweetLimit: parseInt(this.tweetLimit),
      });
      this.updatingUser = false;
      if (response) {
        this.$bvModal.hide("editUserModal");
        swal.fire({
          title: `<strong>${this.$t("modals.editUser.updateSuccess")}</strong>`,
          showConfirmButton: false,
          icon: "success",
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
  },
};
</script>

<style></style>
