<template>
  <div>
    <custom-plan-modal :planId="plans[3].id" />
    <!-- Header -->
    <div class="header bg-gradient-success pb-4 pb-lg-5 pt-7">
      <b-container>
        <div class="header-body text-center mb-8">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">{{ $t("pages.pricing.title") }}</h1>
              <p class="text-lead text-white">
                {{ $t("pages.pricing.text") }}
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="12">
          <b-card-group class="pricing flex-column flex-md-row mb-3">
            <!-- Small Plan -->
            <card header-classes="bg-transparent" class="card-pricing border-0 text-center mb-4">
              <!-- Plan Header -->
              <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">
                {{ plans[0].name }}
              </h4>
              <!-- Plan Content -->
              <div class="d-inline-flex align-items-center">
                <div class="display-2">
                  {{ $t("pages.pricing.price", { price: plans[0].price }) }}
                </div>
                <div class="text-muted">&nbsp;/ {{ $t("pages.pricing.monthly") }}</div>
              </div>
              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-inline-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                        <i class="fas fa-heart"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">
                        {{ plans[0].favCount }}
                        {{ $t("pages.pricing.favorites") }}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-inline-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                        <i class="fab fa-twitter"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">
                        {{ plans[0].tweetLimit }}
                        {{ $t("pages.pricing.tweets") }}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-inline-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                        <i class="fas fa-user"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">
                        {{ plans[0].maxCurrentPlanUser }}
                        {{ $t("pages.pricing.users") }}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>

              <!-- If User Signed In & Has a Plan -->
              <template v-if="signedIn && hasPlan">
                <!-- Waiting For Current Plan -->
                <template v-if="currentPlan == null">
                  <base-button disabled type="primary" class="mb-3">
                    <i class="fas fa-spinner fa-spin"></i>
                  </base-button>
                </template>
                <!-- Current Plan Loaded -->
                <template v-else>
                  <base-button v-if="!plans[0].isAvailable" disabled type="primary" class="mb-3">
                    <span v-if="plans[0].id == currentPlan.planId">
                      {{ $t("pages.pricing.currentPlan") }}
                    </span>
                    <span v-else>
                      {{ $t("pages.pricing.comingSoon") }}
                    </span>
                  </base-button>

                  <base-button
                    v-else
                    @click="purchasePlan(plans[0])"
                    :disabled="initializingPayment || plans[0].id == currentPlan.planId"
                    type="primary"
                    class="mb-3"
                  >
                    <span v-if="plans[0].id == currentPlan.planId">
                      {{ $t("pages.pricing.currentPlan") }}
                    </span>
                    <span v-else>{{ $t("pages.pricing.switchTo") }}</span>
                  </base-button>
                </template>
              </template>
              <!-- If User Not Signed In or Does Not Have a Plan -->
              <template v-else>
                <!-- Plan Is Not Available -->
                <base-button v-if="!plans[0].isAvailable" disabled type="primary" class="mb-3">
                  <span>
                    {{ $t("pages.pricing.comingSoon") }}
                  </span>
                </base-button>
                <!-- Plan Is Available -->
                <base-button
                  v-else
                  @click="purchasePlan(plans[0])"
                  :disabled="initializingPayment"
                  type="primary"
                  class="mb-3"
                >
                  <span>{{ $t("pages.pricing.buyNow") }}</span>
                </base-button>
              </template>
            </card>

            <!-- Large Plan -->
            <card
              gradient="info"
              class="card-pricing zoom-in shadow-lg rounded border-0 text-center mb-4"
              header-classes="bg-transparent"
              footer-classes="bg-transparent"
            >
              <!-- Plan Header -->
              <h4 slot="header" class="text-uppercase ls-1 text-white py-3 mb-0">
                {{ plans[2].name }}
              </h4>
              <!-- Plan Content -->
              <div class="d-inline-flex align-items-center">
                <div class="display-2 text-white">
                  {{ $t("pages.pricing.price", { price: plans[2].price }) }}
                </div>
                <div class="text-white">&nbsp;/ {{ $t("pages.pricing.monthly") }}</div>
              </div>
              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-inline-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted mb-1">
                        <i class="fas fa-heart"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-white">
                        {{ plans[2].favCount }}
                        {{ $t("pages.pricing.favorites") }}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-inline-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted mb-1">
                        <i class="fab fa-twitter"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-white">
                        {{ plans[2].tweetLimit }}
                        {{ $t("pages.pricing.tweets") }}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-inline-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted mb-1">
                        <i class="fas fa-user"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-white">
                        {{ plans[2].maxCurrentPlanUser }}
                        {{ $t("pages.pricing.users") }}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>

              <!-- If User Signed In & Has a Plan -->
              <template v-if="signedIn && hasPlan">
                <!-- Waiting For Current Plan -->
                <template v-if="currentPlan == null">
                  <base-button disabled type="secondary" class="mb-3">
                    <i class="fas fa-spinner fa-spin"></i>
                  </base-button>
                </template>
                <!-- Current Plan Loaded -->
                <template v-else>
                  <base-button v-if="!plans[2].isAvailable" disabled type="secondary" class="mb-3">
                    <span v-if="plans[2].id == currentPlan.planId">
                      {{ $t("pages.pricing.currentPlan") }}
                    </span>
                    <span v-else>
                      {{ $t("pages.pricing.comingSoon") }}
                    </span>
                  </base-button>

                  <base-button
                    v-else
                    @click="purchasePlan(plans[2])"
                    :disabled="initializingPayment || plans[2].id == currentPlan.planId"
                    type="secondary"
                    class="mb-3"
                  >
                    <span v-if="plans[2].id == currentPlan.planId">
                      {{ $t("pages.pricing.currentPlan") }}
                    </span>
                    <span v-else>{{ $t("pages.pricing.switchTo") }}</span>
                  </base-button>
                </template>
              </template>
              <!-- If User Not Signed In or Does Not Have a Plan -->
              <template v-else>
                <!-- Plan Is Not Available -->
                <base-button v-if="!plans[2].isAvailable" disabled type="secondary" class="mb-3">
                  <span>
                    {{ $t("pages.pricing.comingSoon") }}
                  </span>
                </base-button>
                <!-- Plan Is Available -->
                <base-button
                  v-else
                  @click="purchasePlan(plans[2])"
                  :disabled="initializingPayment"
                  type="secondary"
                  class="mb-3"
                >
                  <span>{{ $t("pages.pricing.buyNow") }}</span>
                </base-button>
              </template>
            </card>

            <!-- Medium Plan -->
            <card header-classes="bg-transparent" class="card-pricing border-0 text-center mb-4">
              <!-- Plan Header -->
              <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">
                {{ plans[1].name }}
              </h4>
              <!-- Plan Content -->
              <div class="d-inline-flex align-items-center">
                <div class="display-2">
                  {{ $t("pages.pricing.price", { price: plans[1].price }) }}
                </div>
                <div class="text-muted">&nbsp;/ {{ $t("pages.pricing.monthly") }}</div>
              </div>
              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-inline-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                        <i class="fas fa-heart"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">
                        {{ plans[1].favCount }}
                        {{ $t("pages.pricing.favorites") }}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-inline-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                        <i class="fab fa-twitter"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">{{ plans[1].tweetLimit }} {{ $t("pages.pricing.tweets") }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-inline-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                        <i class="fas fa-user"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">
                        {{ plans[1].maxCurrentPlanUser }}
                        {{ $t("pages.pricing.users") }}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- If User Signed In & Has a Plan -->
              <template v-if="signedIn && hasPlan">
                <!-- Waiting For Current Plan -->
                <template v-if="currentPlan == null">
                  <base-button disabled type="primary" class="mb-3">
                    <i class="fas fa-spinner fa-spin"></i>
                  </base-button>
                </template>
                <!-- Current Plan Loaded -->
                <template v-else>
                  <base-button v-if="!plans[1].isAvailable" disabled type="primary" class="mb-3">
                    <span v-if="plans[1].id == currentPlan.planId">
                      {{ $t("pages.pricing.currentPlan") }}
                    </span>
                    <span v-else>
                      {{ $t("pages.pricing.comingSoon") }}
                    </span>
                  </base-button>

                  <base-button
                    v-else
                    @click="purchasePlan(plans[1])"
                    :disabled="initializingPayment || plans[1].id == currentPlan.planId"
                    type="primary"
                    class="mb-3"
                  >
                    <span v-if="plans[1].id == currentPlan.planId">
                      {{ $t("pages.pricing.currentPlan") }}
                    </span>
                    <span v-else>{{ $t("pages.pricing.switchTo") }}</span>
                  </base-button>
                </template>
              </template>
              <!-- If User Not Signed In or Does Not Have a Plan -->
              <template v-else>
                <!-- Plan Is Not Available -->
                <base-button v-if="!plans[1].isAvailable" disabled type="primary" class="mb-3">
                  <span>
                    {{ $t("pages.pricing.comingSoon") }}
                  </span>
                </base-button>
                <!-- Plan Is Available -->
                <base-button
                  v-else
                  @click="purchasePlan(plans[1])"
                  :disabled="initializingPayment"
                  type="primary"
                  class="mb-3"
                >
                  <span>{{ $t("pages.pricing.buyNow") }}</span>
                </base-button>
              </template>
            </card>
          </b-card-group>
        </b-col>
      </b-row>
      <!-- Redirecting Alert -->
      <b-alert :show="initializingPayment && !upgradingSubscription" variant="neutral">
        <span>
          <i class="fas fa-spinner fa-spin"></i>
          <strong> &nbsp;{{ $t("modals.customPlan.initializingNotification") }} </strong>
        </span>
      </b-alert>
      <!-- Upgrading Alert -->
      <b-alert :show="initializingPayment && upgradingSubscription" variant="neutral">
        <span>
          <i class="fas fa-spinner fa-spin"></i>
          &nbsp;{{ $t("modals.customPlan.upgradingNotification") }}
        </span>
      </b-alert>
      <!-- Customized Plan -->
      <div v-if="plans[3].isAvailable" class="d-flex justify-content-lg-center px-3 mt-5">
        <div>
          <div class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
            <i class="fas fa-info text-primary"></i>
          </div>
        </div>
        <b-col lg="6">
          <p class="text-white">
            <span v-if="$i18n.locale == 'tr'" :class="{ 'disable-links': initializingPayment }">
              Eğer mevcut planlar ihtiyaçlarınızı karşılamıyorsa, özelleştirilmiş bir plan için
              <a href="#" @click="openCustomPlanModal" class="active">
                <strong>buraya tıklayın.</strong>
              </a>
            </span>
            <span v-else :class="{ 'disable-links': initializingPayment }">
              If none of these plans meet your needs,
              <a href="#" @click="openCustomPlanModal" class="active">
                <strong>click here</strong>
              </a>
              to get a personalized plan.
            </span>
          </p>
        </b-col>
      </div>
      <!-- Features Table -->
      <b-row class="row-grid justify-content-center">
        <b-col lg="10">
          <div class="table-responsive">
            <base-table class="table table-dark mt-5" :data="features">
              <template slot="columns">
                <th class="px-0 bg-transparent" scope="col">
                  <span class="text-light font-weight-700">{{ $t("pages.pricing.features") }}</span>
                </th>
                <th class="text-center bg-transparent" scope="col">{{ plans[0].name }} Plan</th>
                <th class="text-center bg-transparent" scope="col">{{ plans[1].name }} Plan</th>
                <th class="text-center bg-transparent" scope="col">{{ plans[2].name }} Plan</th>
              </template>

              <template slot-scope="{ row }">
                <td class="px-0">
                  {{ row.title }}
                  <i class="fas fa-info-circle" v-b-tooltip.hover.right :title="row.tip"></i>
                </td>
                <td class="text-center" v-html="row.small"></td>
                <td class="text-center" v-html="row.medium"></td>
                <td class="text-center" v-html="row.large"></td>
              </template>
            </base-table>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import BaseTable from "@/components/BaseTable";
import CustomPlanModal from "../Modals/CustomPlan.vue";
import { mapGetters, mapState } from "vuex";

export default {
  props: ["plans"],
  metaInfo() {
    return {
      title: this.$t("routerTitles.pricing"),
    };
  },
  components: {
    BaseTable,
    CustomPlanModal,
  },
  computed: {
    ...mapGetters("auth", ["signedIn"]),
    ...mapState("currentPlan", ["currentPlan"]),
    ...mapState("user", ["hasPlan"]),
  },
  data() {
    return {
      date: new Date(),
      initializingPayment: false,
      upgradingSubscription: false,
      features: [
        {
          title: this.$t("pages.pricing.includeComments"),
          tip: this.$t("pages.pricing.includeCommentsTip"),
          small: '<i class="fas fa-check text-success"></i>',
          medium: '<i class="fas fa-check text-success"></i>',
          large: '<i class="fas fa-check text-success"></i>',
        },
        {
          title: this.$t("pages.pricing.includeRetweets"),
          tip: this.$t("pages.pricing.includeRetweetsTip"),
          small: '<i class="fas fa-check text-success"></i>',
          medium: '<i class="fas fa-check text-success"></i>',
          large: '<i class="fas fa-check text-success"></i>',
        },
        {
          title: this.$t("pages.pricing.instantDelivery"),
          tip: this.$t("pages.pricing.instantDeliveryTip"),
          small: '<i class="fas fa-check text-success"></i>',
          medium: '<i class="fas fa-check text-success"></i>',
          large: '<i class="fas fa-check text-success"></i>',
        },
        {
          title: this.$t("pages.pricing.delayedDelivery"),
          tip: this.$t("pages.pricing.delayedDeliveryTip"),
          small: '<i class="fas fa-check text-success"></i>',
          medium: '<i class="fas fa-check text-success"></i>',
          large: '<i class="fas fa-check text-success"></i>',
        },
        {
          title: this.$t("pages.pricing.naturalDelivery"),
          tip: this.$t("pages.pricing.naturalDeliveryTip"),
          small: '<i class="fas fa-check text-success"></i>',
          medium: '<i class="fas fa-check text-success"></i>',
          large: '<i class="fas fa-check text-success"></i>',
        },
        {
          title: this.$t("pages.pricing.favoriteType"),
          tip: this.$t("pages.pricing.favoriteTypeTip"),
          small: '<i class="fas fa-check text-success"></i>',
          medium: '<i class="fas fa-check text-success"></i>',
          large: '<i class="fas fa-check text-success"></i>',
        },
        {
          title: this.$t("pages.pricing.randomFavoriteType"),
          tip: this.$t("pages.pricing.randomFavoriteTypeTip"),
          small: '<i class="fas fa-check text-success"></i>',
          medium: '<i class="fas fa-check text-success"></i>',
          large: '<i class="fas fa-check text-success"></i>',
        },
      ],
    };
  },
  async created() {
    if (this.signedIn) {
      const hasPlan = await this.getHasPlan();
      if (hasPlan) await this.getCurrentPlan();
    }
  },
  methods: {
    openCustomPlanModal() {
      this.$bvModal.show("customPlanModal");
    },
    async getCurrentPlan() {
      await this.$store.dispatch("currentPlan/getCurrentPlan");
    },
    async getHasPlan() {
      const response = await this.$store.dispatch("user/hasPlan");
      return response.data.hasPlan;
    },
    async purchasePlan(plan) {
      if (this.signedIn) {
        this.initializingPayment = true;
        if (!this.hasPlan) {
          const response = await this.$store.dispatch("subscription/checkout", {
            priceId: plan.priceId,
          });
          this.initializingPayment = false;
          if (response) window.location = response.data.url;
        } else {
          this.upgradingSubscription = true;
          const response = await this.$store.dispatch("subscription/upgrade", {
            priceId: plan.priceId,
          });
          this.initializingPayment = false;
          this.upgradingSubscription = false;
          if (response) {
            swal.fire({
              title: this.$t("pages.pricing.subscriptionUpdatedTitle"),
              html: this.$t("pages.pricing.subscriptionUpdatedText", {
                date: this.$d(new Date(parseInt(response.data.renewDate)), "short"),
                price: plan.price,
                plan: plan.name,
              }),
              showConfirmButton: true,
              confirmButtonText: this.$t("pages.pricing.subscriptionUpdatedConfirm"),
              confirmButtonColor: "#5e72e4",
              icon: "success",
            });
          }
        }
      } else {
        this.$router.push({
          name: "signIn",
          query: { redirect: this.$i18n.t("routerPaths.pricing") },
        });
      }
    },
  },
};
</script>
<style>
span.disable-links {
  pointer-events: none !important;
  cursor: default;
}
</style>
