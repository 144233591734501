<template>
  <b-col xl="4">
    <!-- Content -->
    <template v-if="!showLoader">
      <!-- Show If User Does Not Have A Plan-->
      <template v-if="showNoPlan">
        <b-card class="details-card" no-body>
          <!-- Card header -->
          <b-card-header>
            <!-- Title -->
            <b-row align-v="center">
              <b-col>
                <h3 class="mb-0" style="line-height: 1.7">
                  {{ $t("widgets.currentUserDetails.userDetails") }}
                </h3>
              </b-col>
            </b-row>
          </b-card-header>
          <!-- No User Card Boy -->
          <b-card-body class="currentUserDetailsScrollArea" style="position: relative">
            <div class="text-center mt-4">
              <i class="fas fa-exclamation-circle text-primary display-1"></i>
              <h3 class="text-muted">{{ $t("widgets.general.noPlanInfo") }}</h3>
            </div>
          </b-card-body>
        </b-card>
      </template>
      <!-- Show If User Has A Plan-->
      <template v-else>
        <b-card class="details-card" no-body>
          <!-- Card header -->
          <b-card-header>
            <!-- Title -->
            <b-row align-v="center">
              <b-col xl="8" md="6">
                <h3 class="mb-0" style="line-height: 1.7">
                  {{
                    `${$t("widgets.currentUserDetails.userDetails")} ${
                      Object.keys(currentUser).length > 0 ? `(@${currentUser.username})` : ""
                    }`
                  }}
                </h3>
              </b-col>
              <b-col v-if="Object.keys(currentUser).length > 0" xl="4" md="6" class="text-md-right">
                <base-button size="sm" type="primary" @click="$bvModal.show('editUserModal')">{{
                  $t("widgets.currentUserDetails.edit")
                }}</base-button>
              </b-col>
            </b-row>
          </b-card-header>
          <!-- Card body -->
          <b-card-body
            v-if="Object.keys(currentUser).length > 0"
            class="currentUserDetailsScrollArea"
            style="position: relative"
          >
            <!-- Current User Details List -->
            <b-list-group flush class="list my--3">
              <!-- User Status -->
              <b-list-group-item class="px-0">
                <b-row align-v="center">
                  <!-- User Status Icon -->
                  <b-col md="auto" style="width: auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-info">
                      <i class="fas fa-check"></i>
                    </div>
                  </b-col>
                  <!-- User Status Content -->
                  <b-col>
                    <h5 style="margin-bottom: 0rem !important">
                      {{ $t("widgets.currentUserDetails.accountStatus") }}
                    </h5>
                    <small :class="`text-${currentUser.isActive ? 'success' : 'danger'}`">
                      <strong>{{
                        currentUser.isActive
                          ? $t("widgets.currentUserDetails.active")
                          : $t("widgets.currentUserDetails.passive")
                      }}</strong>
                    </small>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <!-- Natural Delivery -->
              <b-list-group-item class="px-0">
                <b-row align-v="center">
                  <!-- Natural Delivery Icon -->
                  <b-col md="auto" style="width: auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-info">
                      <i class="fas fa-leaf"></i>
                    </div>
                  </b-col>
                  <!-- Natural Delivery Content -->
                  <b-col>
                    <h5 style="margin-bottom: 0rem !important">
                      {{ $t("widgets.currentUserDetails.naturalDelivery") }}
                    </h5>
                    <small :class="`text-${currentUser.naturalSend ? 'success' : 'danger'}`">
                      <strong>{{
                        currentUser.naturalSend
                          ? $t("widgets.currentUserDetails.active")
                          : $t("widgets.currentUserDetails.disabled")
                      }}</strong>
                    </small>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <!-- Delay -->
              <b-list-group-item class="px-0">
                <b-row align-v="center">
                  <!-- Delay Icon -->
                  <b-col md="auto" style="width: auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-info">
                      <i class="fas fa-clock"></i>
                    </div>
                  </b-col>
                  <!-- Delay Content -->
                  <b-col>
                    <h5 style="margin-bottom: 0rem !important">
                      {{ $t("widgets.currentUserDetails.delay") }}
                    </h5>
                    <small>
                      <strong v-if="currentUser.delay > 0">{{
                        `${currentUser.delay} ${$t("widgets.currentUserDetails.second")}`
                      }}</strong>
                      <strong v-else>
                        {{ $t("widgets.currentUserDetails.noDelay") }}
                      </strong>
                    </small>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <!-- Reaction Type -->
              <b-list-group-item class="px-0">
                <b-row align-v="center">
                  <!-- Reaction Type Title -->
                  <b-col md="auto" style="width: auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-info">
                      <i class="fas fa-heart"></i>
                    </div>
                  </b-col>
                  <!-- Reaction Type Content -->
                  <b-col>
                    <h5 style="margin-bottom: 0rem !important">
                      {{ $t("widgets.currentUserDetails.reactionType") }}
                    </h5>
                    <small>
                      <strong
                        v-for="reaction in reactionTypes.filter(
                          (reaction) => reaction.value == currentUser.reactionType
                        )"
                        :key="reaction.value"
                        >{{ reaction.label }}
                      </strong>
                    </small>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <!-- Include Retweets -->
              <b-list-group-item class="px-0">
                <b-row align-v="center">
                  <!-- Include Retweets Icon -->
                  <b-col md="auto" style="width: auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-info">
                      <i class="fas fa-retweet"></i>
                    </div>
                  </b-col>
                  <!-- Include Retweets Content -->
                  <b-col>
                    <h5 style="margin-bottom: 0rem !important">
                      {{ $t("widgets.currentUserDetails.includeRetweets") }}
                    </h5>
                    <small :class="`text-${currentUser.includeRetweets ? 'success' : 'danger'}`">
                      <strong>{{
                        currentUser.includeRetweets
                          ? $t("widgets.currentUserDetails.active")
                          : $t("widgets.currentUserDetails.disabled")
                      }}</strong>
                    </small>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <!-- Include Comments  -->
              <b-list-group-item class="px-0">
                <b-row align-v="center">
                  <!-- Include Comments Icon -->
                  <b-col md="auto" style="width: auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-info">
                      <i class="fas fa-comment"></i>
                    </div>
                  </b-col>
                  <!-- Include Comments Content -->
                  <b-col>
                    <h5 style="margin-bottom: 0rem !important">
                      {{ $t("widgets.currentUserDetails.includeComments") }}
                    </h5>
                    <small :class="`text-${currentUser.includeComments ? 'success' : 'danger'}`">
                      <strong>{{
                        currentUser.includeComments
                          ? $t("widgets.currentUserDetails.active")
                          : $t("widgets.currentUserDetails.disabled")
                      }}</strong>
                    </small>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
          <!-- No User Card Boy -->
          <b-card-body v-else class="currentUserDetailsScrollArea" style="position: relative">
            <div class="text-center mt-4">
              <i class="fas fa-exclamation-circle text-primary display-1"></i>
              <h3 class="text-muted">{{ $t("widgets.general.noUserInfo") }}</h3>
            </div>
          </b-card-body>
        </b-card>
      </template>
    </template>
    <!-- Placeholder -->
    <template v-else>
      <b-card body-class="details-placeholder-card">
        <ContentLoader height="420" width="516" secondaryColor="#b8b8b8">
          <rect x="30" y="30" rx="2" ry="2" width="190" height="20" />
          <rect x="80" y="94" rx="2" ry="2" width="100" height="10" />
          <circle cx="50" cy="105" r="20" />
          <rect x="80" y="109" rx="2" ry="2" width="80" height="10" />
          <rect x="430" y="30" rx="2" ry="2" width="50" height="25" />
          <rect x="80" y="179" rx="2" ry="2" width="100" height="10" />
          <circle cx="50" cy="190" r="20" />
          <rect x="80" y="194" rx="2" ry="2" width="80" height="10" />
          <rect x="80" y="264" rx="2" ry="2" width="100" height="10" />
          <circle cx="50" cy="275" r="20" />
          <rect x="80" y="279" rx="2" ry="2" width="80" height="10" />
          <rect x="80" y="349" rx="2" ry="2" width="100" height="10" />
          <circle cx="50" cy="360" r="20" />
          <rect x="80" y="364" rx="2" ry="2" width="80" height="10" />
        </ContentLoader>
      </b-card>
    </template>
  </b-col>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`, { wheelPropagation: false });
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { mapGetters, mapState } from "vuex";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    ContentLoader,
  },
  computed: {
    ...mapGetters("currentPlan", ["currentUser"]),
    ...mapState("user", ["hasPlan"]),
    showLoader() {
      if (this.hasPlan == null) {
        return true;
      } else {
        if (this.hasPlan && this.currentUser == null) return true;
        else return false;
      }
    },
    showNoPlan() {
      if (!this.hasPlan) return true;
      else return false;
    },
    reactionTypes() {
      return [
        {
          label: this.$t("reactionTypes.random"),
          value: 1,
        },
        {
          label: this.$t("reactionTypes.hmm"),
          value: 2,
        },
        {
          label: this.$t("reactionTypes.sad"),
          value: 3,
        },
        {
          label: this.$t("reactionTypes.haha"),
          value: 4,
        },
        {
          label: this.$t("reactionTypes.cheer"),
          value: 5,
        },
        {
          label: this.$t("reactionTypes.like"),
          value: 6,
        },
      ];
    },
  },
  mounted() {
    this.initScrollbar();
  },
  methods: {
    initScrollbar() {
      initScrollbar("currentUserDetailsScrollArea");
    },
  },
};
</script>

<style></style>
