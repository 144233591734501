<template>
  <div>
    <template v-if="plans != null">
      <custom-plan-modal :planId="plans[3].id" />
    </template>
    <base-header class="pb-6 pt-5">
      <!-- Current Subscription Details -->
      <subscription-details :plans="plans" />
    </base-header>
    <b-container fluid class="mt--6">
      <!-- Avilable Plans -->
      <subscription-plans :plans="plans" />
      <!-- Current Plan Note-->
      <b-row v-if="currentPlan != null" class="text-center">
        <b-col v-if="currentPlan.note != undefined" class="justify-content-center">
          <b-alert id="subscriptionNotification" class="subs-alert bg-gradient-default" :show="true" variant="default">
            <div class="d-flex align-items-center justify-content-center">
              <span class="blinking">
                <i style="font-size: 1.8rem" class="fas fa-exclamation-circle mr-2"></i>
              </span>
              <span>{{ currentPlan.note }} </span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <subscription-actions :plans="plans" />
    </b-container>
  </div>
</template>

<script>
import SubscriptionDetails from "../../Widgets/SubscriptionDetails.vue";
import SubscriptionPlans from "../../Widgets/SubscriptionPlans.vue";
import SubscriptionActions from "../../Widgets/SubscriptionActions.vue";
import CustomPlanModal from "../../Modals/CustomPlan.vue";

import { mapState } from "vuex";

export default {
  props: ["plans"],
  metaInfo() {
    return {
      title: this.$t("routerTitles.subscription"),
    };
  },
  components: {
    SubscriptionDetails,
    SubscriptionPlans,
    SubscriptionActions,
    CustomPlanModal,
  },
  computed: {
    ...mapState("currentPlan", ["currentPlan"]),
  },
};
</script>
<style lang="scss">
.blinking {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.subs-alert {
  font-size: 0.9rem;
}

.subs-card {
  min-height: 120px !important;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}
</style>
