<template>
  <div>
    <base-header class="pb-5 pt-5"> </base-header>

    <b-container fluid class="mt--5">
      <b-row>
        <b-col xl="4" class="order-xl-2">
          <my-profile-user :plans="plans"></my-profile-user>
        </b-col>
        <b-col xl="8" class="order-xl-1">
          <my-profile-form></my-profile-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import MyProfileForm from "../../Widgets/MyProfileForm.vue";
import MyProfileUser from "../../Widgets/MyProfileUser.vue";

export default {
  props: ["plans"],
  metaInfo() {
    return {
      title: this.$t("routerTitles.myProfile"),
    };
  },
  components: {
    MyProfileForm,
    MyProfileUser,
  },
};
</script>
<style></style>
