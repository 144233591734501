<template>
  <div>
    <template v-if="isRequestValid">
      <!-- Header -->
      <div class="header bg-gradient-success pb-4 pb-lg-5 pt-7">
        <b-container>
          <div class="header-body text-center mb-8">
            <b-row class="justify-content-center">
              <b-col xl="5" lg="6" md="8" class="px-5">
                <h1 class="text-white">
                  {{ $t("pages.payment.cancel.title") }}
                </h1>
              </b-col>
            </b-row>
          </div>
        </b-container>
        <div class="separator separator-bottom separator-skew zindex-100">
          <svg
            x="0"
            y="0"
            viewBox="0 0 2560 100"
            preserveAspectRatio="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
      </div>
      <!-- Page content -->
      <b-container class="mt--8 pb-5">
        <b-row class="justify-content-center">
          <b-col cols="12" md="10">
            <b-card no-body>
              <b-card-body>
                <div class="text-center px-md-4">
                  <div class="fas fa-exclamation-circle text-center text-info mb-4" style="font-size: 7rem"></div>
                  <div v-html="$t('pages.payment.cancel.text')"></div>
                  <div class="my-4">
                    <b-btn variant="primary" @click="$router.push({ name: 'subscription' })"
                      >{{ $t("pages.payment.cancel.buttonText") }}
                    </b-btn>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: this.$t("routerTitles.payment.canceled"),
    };
  },
  data: () => ({
    isRequestValid: false,
  }),
  created() {
    if (!document.referrer.includes("https://checkout.stripe.com/")) this.$router.push({ name: "signIn" });
    else this.isRequestValid = true;
  },
};
</script>
<style></style>
