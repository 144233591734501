<template>
  <b-col xl="4">
    <!-- Content -->
    <template v-if="!showLoader">
      <!-- Show If User Does Not Have A Plan -->
      <template v-if="showNoPlan">
        <b-card class="details-card" no-body>
          <!-- Card header -->
          <b-card-header>
            <b-row align-v="center">
              <!-- Title -->
              <b-col>
                <h3 class="mb-0" style="line-height: 1.7">
                  {{ $t("widgets.currentPlanUsers.users") }}
                </h3>
              </b-col>
            </b-row>
          </b-card-header>
          <!-- No User Card Body -->
          <b-card-body class="currentPlanUsersScrollArea" style="position: relative">
            <div class="text-center mt-4">
              <i class="fas fa-exclamation-circle text-primary display-1"></i>
              <h3 class="text-muted">{{ $t("widgets.general.noPlanInfo") }}</h3>
            </div>
          </b-card-body>
        </b-card>
      </template>
      <!-- Show If User Has A Plan -->
      <template v-else>
        <b-card class="details-card" no-body>
          <!-- Card header -->
          <b-card-header>
            <b-row align-v="center">
              <!-- Title -->
              <b-col xl="8" md="6">
                <h3 class="mb-0" style="line-height: 1.7">
                  {{ $t("widgets.currentPlanUsers.users") }}
                </h3>
              </b-col>
              <!-- Add Button -->
              <b-col xl="4" md="6" class="text-md-right">
                <base-button
                  :disabled="
                    parseInt(currentPlan.currentPlanUsers ? currentPlanUsers.length : 0) ==
                    currentPlan.maxCurrentPlanUser
                  "
                  size="sm"
                  type="primary"
                  @click="$bvModal.show('addUserModal')"
                  >{{ $t("widgets.currentPlanUsers.addUser") }}
                </base-button>
              </b-col>
            </b-row>
          </b-card-header>
          <!-- Card body -->
          <b-card-body
            v-if="currentPlan.currentPlanUsers"
            class="currentPlanUsersScrollArea"
            style="position: relative"
          >
            <!-- Current Plan Users List -->
            <b-list-group flush class="list my--3">
              <!-- User -->
              <b-list-group-item v-for="user in currentPlanUsers" :key="user.id" class="list-group-item px-0">
                <b-row align-v="center">
                  <!-- Icon -->
                  <b-col md="auto" style="width: auto">
                    <span class="avatar rounded-circle">
                      <b-img alt="User Image" src="img/icons/user.png" />
                    </span>
                  </b-col>
                  <!-- Username & Status -->
                  <b-col class="ml--2">
                    <h4 class="mb-0">
                      <a :href="`https://twitter.com/${user.username}`" target="_blank">{{ user.username }}</a>
                    </h4>
                    <span :class="`text-${user.isActive ? 'success' : 'danger'}`">● </span>
                    <small>{{
                      user.isActive ? $t("widgets.currentPlanUsers.active") : $t("widgets.currentPlanUsers.passive")
                    }}</small>
                  </b-col>
                  <!-- Button & Badge -->
                  <b-col md="auto" style="width: auto">
                    <b-badge v-if="currentUser.id == user.id" variant="primary">
                      {{ $t("widgets.currentPlanUsers.showing") }}
                    </b-badge>
                    <b-button v-else type="button" size="sm" variant="primary" @click="setCurrentUser(user.username)">
                      {{ $t("widgets.currentPlanUsers.choose") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
          <!-- No User Card Body -->
          <b-card-body v-else class="currentPlanUsersScrollArea" style="position: relative">
            <div class="text-center mt-4">
              <i class="fas fa-exclamation-circle text-primary display-1"></i>
              <h3 class="text-muted">{{ $t("widgets.general.noUserInfo") }}</h3>
            </div>
          </b-card-body>
        </b-card>
      </template>
    </template>
    <!-- Placeholder -->
    <template v-else>
      <b-card body-class="details-placeholder-card">
        <ContentLoader height="420" width="516" secondaryColor="#b8b8b8">
          <rect x="30" y="30" rx="2" ry="2" width="190" height="20" />
          <rect x="80" y="94" rx="2" ry="2" width="100" height="10" />
          <circle cx="50" cy="105" r="20" />
          <rect x="80" y="109" rx="2" ry="2" width="80" height="10" />
          <rect x="430" y="94" rx="2" ry="2" width="50" height="25" />
          <rect x="80" y="179" rx="2" ry="2" width="100" height="10" />
          <circle cx="50" cy="190" r="20" />
          <rect x="80" y="194" rx="2" ry="2" width="80" height="10" />
          <rect x="430" y="179" rx="2" ry="2" width="50" height="25" />
          <rect x="80" y="264" rx="2" ry="2" width="100" height="10" />
          <circle cx="50" cy="275" r="20" />
          <rect x="80" y="279" rx="2" ry="2" width="80" height="10" />
          <rect x="430" y="264" rx="2" ry="2" width="50" height="25" />
          <rect x="80" y="349" rx="2" ry="2" width="100" height="10" />
          <circle cx="50" cy="360" r="20" />
          <rect x="80" y="364" rx="2" ry="2" width="80" height="10" />
          <rect x="430" y="349" rx="2" ry="2" width="50" height="25" />
        </ContentLoader>
      </b-card>
    </template>
  </b-col>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`, { wheelPropagation: false });
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { mapGetters, mapState } from "vuex";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    ContentLoader,
  },
  computed: {
    ...mapGetters("currentPlan", ["currentUser"]),
    ...mapState("currentPlan", ["currentPlan"]),
    ...mapState("user", ["hasPlan"]),
    showLoader() {
      if (this.hasPlan == null) {
        return true;
      } else {
        if (this.hasPlan && this.currentPlan == null) return true;
        else return false;
      }
    },
    showNoPlan() {
      if (!this.hasPlan) return true;
      else return false;
    },
    currentPlanUsers() {
      return this.currentPlan.currentPlanUsers;
    },
  },
  mounted() {
    this.initScrollbar();
  },
  methods: {
    setCurrentUser(username) {
      this.$store.dispatch("currentPlan/setCurrentUser", username);
    },
    initScrollbar() {
      initScrollbar("currentPlanUsersScrollArea");
    },
  },
};
</script>

<style></style>
