var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header bg-gradient-success pb-4 pb-lg-5 pt-7"},[_c('b-container',[_c('div',{staticClass:"header-body text-center mb-8"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{staticClass:"px-5",attrs:{"xl":"5","lg":"6","md":"8"}},[_c('h1',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.$t("auth.resetPassword")))]),_c('p',{staticClass:"text-lead text-white"},[_vm._v(" "+_vm._s(_vm.$t("auth.resetPasswordHeader"))+" ")])])],1)],1)]),_c('div',{staticClass:"separator separator-bottom separator-skew zindex-100"},[_c('svg',{attrs:{"x":"0","y":"0","viewBox":"0 0 2560 100","preserveAspectRatio":"none","version":"1.1","xmlns":"http://www.w3.org/2000/svg"}},[_c('polygon',{staticClass:"fill-default",attrs:{"points":"2560 0 2560 100 0 100"}})])])],1),_c('b-container',{staticClass:"mt--8 pb-5"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"lg":"5","md":"7"}},[(!_vm.isPasswordChanged)?_c('b-card',{staticClass:"bg-secondary border-0 mb-0",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"bg-transparent"},[_c('div',{staticClass:"text-muted text-center mt-2 mb-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t("auth.resetPassword")))])])]),_c('b-card-body',{staticClass:"px-lg-5 py-lg-5"},[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('base-input',{ref:"newPassword",staticClass:"mb-3",attrs:{"alternative":"","type":"password","prepend-icon":"ni ni-lock-circle-open","name":"password","rules":{
                    required: true,
                    min: 6,
                    regex: /^((?=.*?[a-z])(?=.*?[0-9])).{6,32}$/,
                  },"placeholder":_vm.$t('auth.newPassword')},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}},[_c('template',{slot:"append"},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],staticClass:"fas fa-info-circle",attrs:{"title":_vm.$t('auth.passwordHint')}})])],2),_c('base-input',{staticClass:"mb-3",attrs:{"alternative":"","type":"password","prepend-icon":"ni ni-lock-circle-open","name":"passwordConfirm","rules":{
                    required: true,
                    confirmed: 'password',
                  },"placeholder":_vm.$t('auth.newPasswordConfirm')},model:{value:(_vm.model.confirmationPassword),callback:function ($$v) {_vm.$set(_vm.model, "confirmationPassword", $$v)},expression:"model.confirmationPassword"}}),_c('div',{staticClass:"text-center"},[_c('base-button',{staticClass:"my-4",attrs:{"block":"","loading":_vm.isLoading,"type":"primary","native-type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("auth.changePassword"))+" ")])],1)],1)]}}],null,false,1821394166)})],1)],1):_c('b-card',{staticClass:"bg-secondary border-0 mb-0",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"px-lg-5 py-lg-5"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"far fa-check-circle text-center text-success mb-4",staticStyle:{"font-size":"6rem"}}),_c('p',{staticClass:"text-center text-big mb-4"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('auth.resetPasswordSuccess'))}})]),_c('base-button',{staticClass:"my-2",attrs:{"block":"","type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'signIn' })}}},[_vm._v(" "+_vm._s(_vm.$t("auth.loginToYourAccount"))+" ")])],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }