<template>
  <card>
    <!-- Title -->
    <b-row align-v="center" slot="header">
      <b-col cols="8">
        <h3 class="mb-0">{{ $t("pages.myProfile.myProfile") }}</h3>
      </b-col>
    </b-row>

    <!-- User Information -->
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
      <b-form role="form" @submit.prevent="handleSubmit(updateUser)">
        <h6 class="heading-small text-muted mb-4">{{ $t("pages.myProfile.userInformation") }}</h6>
        <div>
          <!-- Email Address -->
          <b-row>
            <b-col lg="6">
              <base-input
                disabled
                type="email"
                :label="$t('pages.myProfile.emailAddress')"
                :placeholder="$t('pages.myProfile.emailAddress')"
                v-model="userToUpdate.emailAddress"
              >
              </base-input>
            </b-col>
          </b-row>
          <!-- First Name & Last Name -->
          <b-row>
            <b-col lg="6">
              <base-input
                type="text"
                name="firstName"
                :label="$t('pages.myProfile.firstName')"
                :placeholder="$t('pages.myProfile.firstName')"
                :rules="{
                  required: true,
                  min: 3,
                  regex: /^[a-zA-ZğüçışöĞÜÇİŞÖ]+(?: [a-zA-ZğüçışöĞÜÇİŞÖ]+)*$/,
                }"
                v-model="userToUpdate.firstName"
              >
              </base-input>
            </b-col>
            <b-col lg="6">
              <base-input
                type="text"
                name="lastName"
                :label="$t('pages.myProfile.lastName')"
                :placeholder="$t('pages.myProfile.lastName')"
                :rules="{
                  required: true,
                  min: 2,
                  regex: /^[a-zA-ZğüçışöĞÜÇİŞÖ]+(?: [a-zA-ZğüçışöĞÜÇİŞÖ]+)*$/,
                }"
                v-model="userToUpdate.lastName"
              >
              </base-input>
            </b-col>
          </b-row>
          <!-- Update User Button -->
          <b-row>
            <base-button :disabled="!isUserUpdateAvailable" class="btn-block mx-3" native-type="submit">
              {{ $t("pages.myProfile.updateUser") }}
            </base-button>
          </b-row>
        </div>
      </b-form>
    </validation-observer>

    <hr class="my-4" />

    <!-- Password -->
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
      <b-form @submit.prevent="handleSubmit(changePassword)">
        <h6 class="heading-small text-muted mb-4">{{ $t("pages.myProfile.password") }}</h6>

        <div>
          <!-- Current Password -->
          <b-row>
            <b-col md="6">
              <base-input
                type="password"
                name="currentPassword"
                :label="$t('pages.myProfile.currentPassword')"
                :placeholder="$t('pages.myProfile.currentPassword')"
                :rules="{
                  required: true,
                }"
                v-model="password.currentPassword"
              >
              </base-input>
            </b-col>
          </b-row>
          <!-- New Password & New Password Confirmation -->
          <b-row>
            <b-col lg="6">
              <base-input
                type="password"
                name="newPassword"
                :label="$t('pages.myProfile.newPassword')"
                :placeholder="$t('pages.myProfile.newPassword')"
                :rules="{
                  required: true,
                  min: 6,
                  regex: /^((?=.*?[a-z])(?=.*?[0-9])).{6,32}$/,
                }"
                v-model="password.newPassword"
              >
              </base-input>
            </b-col>
            <b-col lg="6">
              <base-input
                type="password"
                name="newPasswordConfirm"
                :label="$t('pages.myProfile.newPasswordConfirm')"
                :placeholder="$t('pages.myProfile.newPasswordConfirm')"
                :rules="{
                  required: true,
                  confirmed: 'newPassword',
                }"
                v-model="password.newPasswordConfirm"
              >
              </base-input>
            </b-col>
          </b-row>
          <!-- Change Password Button -->
          <b-row>
            <base-button :disabled="!isChangePasswordAvailable" class="btn-block mx-3" native-type="submit">
              {{ $t("pages.myProfile.changePassword") }}
            </base-button>
          </b-row>
        </div>
      </b-form>
    </validation-observer>
  </card>
</template>
<script>
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("auth", ["user"]),
    isUserUpdateAvailable() {
      if (this.user != null) {
        if (this.userToUpdate.firstName != this.user.firstName || this.userToUpdate.lastName != this.user.lastName)
          return true;
        else return false;
      }
      return false;
    },
    isChangePasswordAvailable() {
      if (
        this.password.currentPassword != "" &&
        this.password.newPassword != "" &&
        this.password.newPasswordConfirm != ""
      )
        return true;
      else return false;
    },
  },
  data: () => ({
    userToUpdate: {
      emailAddress: "",
      firstName: "",
      lastName: "",
    },
    password: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
  }),
  mounted() {
    this.userToUpdate.emailAddress = this.user.emailAddress;
    this.userToUpdate.firstName = this.user.firstName;
    this.userToUpdate.lastName = this.user.lastName;
  },
  methods: {
    async updateUser() {
      let vm = this;
      // Show Loading Alert
      swal.fire({
        title: "<i class='fas fa-spinner fa-spin' style='font-size: 2.5rem; margin-top:2rem;'></i>",
        html: vm.$t("pages.myProfile.updatingUserNotification"),
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onBeforeOpen: async function () {
          const response = await vm.$store.dispatch("user/updateUser", {
            emailAddress: vm.userToUpdate.emailAddress,
            firstName: vm.userToUpdate.firstName,
            lastName: vm.userToUpdate.lastName,
          });
          swal.close();
          // Show Success Alert If User Updated
          if (response) {
            swal.fire({
              title: vm.$t("pages.myProfile.updatingUserSuccess"),
              showConfirmButton: false,
              icon: "success",
              timer: 3000,
              timerProgressBar: true,
            });
          }
        },
      });
    },
    async changePassword() {
      let vm = this;
      // Show Loading Alert
      swal.fire({
        title: "<i class='fas fa-spinner fa-spin' style='font-size: 2.5rem; margin-top:2rem;'></i>",
        html: vm.$t("pages.myProfile.changingPasswordNotification"),
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onBeforeOpen: async function () {
          const response = await vm.$store.dispatch("user/changePassword", {
            currentPassword: vm.password.currentPassword,
            newPassword: vm.password.newPassword,
          });
          swal.close();
          // Show Success Alert If Password Changed
          if (response) {
            swal.fire({
              title: vm.$t("pages.myProfile.changingPasswordSuccess"),
              html: vm.$t("pages.myProfile.changingPasswordSuccessHint"),
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              icon: "success",
              timer: 3000,
              timerProgressBar: true,
              onDestroy: () => {
                vm.$store.dispatch("auth/signOut");
              },
            });
          }
        },
      });
    },
  },
};
</script>
<style></style>
