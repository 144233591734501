<template>
  <b-modal
    id="addUserModal"
    :title="$t('modals.addUser.addNewUser')"
    hide-footer
    modal-class="px-0"
    body-class="pt-0"
    no-close-on-backdrop
  >
    <!-- Adding Unavailable Alert -->
    <b-alert :show="!isAvailable" variant="danger">
      <span>
        <i class="fas fa-exclamation-circle"></i>
        <strong> &nbsp;{{ $t("modals.addUser.unavailable") }} </strong>
      </span>
    </b-alert>
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
      <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
        <!-- Username -->
        <base-input
          :disabled="!isAvailable || addingUser"
          name="username"
          v-model="username"
          :rules="{
            required: true,
            regex: /^[a-zA-Z_0-9]+(?:[a-zA-Z_0-9]+)*$/,
          }"
          prepend-icon="fas fa-at"
          :placeholder="$t('modals.addUser.username')"
        >
        </base-input>

        <!-- Include Retweets -->
        <base-checkbox
          :disabled="!isAvailable || addingUser"
          class="mb-1"
          name="includeRetweets"
          v-model="includeRetweets"
        >
          {{ $t("modals.addUser.includeRetweets") }}
          <i class="fas fa-info-circle" v-b-tooltip.hover.bottom :title="$t('modals.tips.includeRetweets')"></i>
        </base-checkbox>

        <!-- Include Comments -->
        <base-checkbox
          :disabled="!isAvailable || addingUser"
          class="mb-1"
          name="includeComments"
          v-model="includeComments"
        >
          {{ $t("modals.addUser.includeComments") }}
          <i class="fas fa-info-circle" v-b-tooltip.hover.bottom :title="$t('modals.tips.includeComments')"></i>
        </base-checkbox>

        <!-- Natural Delivery -->
        <base-checkbox
          :disabled="!isAvailable || addingUser"
          class="mb-1"
          name="naturalDelivery"
          v-model="naturalDelivery"
        >
          {{ $t("modals.addUser.naturalDelivery") }}
          <i class="fas fa-info-circle" v-b-tooltip.hover.bottom :title="$t('modals.tips.naturalDelivery')"></i>
        </base-checkbox>

        <!-- Delayed Delivery -->
        <div class="mb-3">
          <base-checkbox
            :disabled="!isAvailable || addingUser"
            class="mb-2"
            name="delayedDelivery"
            v-model="delayedDelivery"
          >
            {{ $t("modals.addUser.delayedDelivery") }}
            <i class="fas fa-info-circle" v-b-tooltip.hover.bottom :title="$t('modals.tips.delayedDelivery')"></i>
          </base-checkbox>
          <base-input
            v-if="delayedDelivery"
            :disabled="!isAvailable || addingUser"
            name="delayTime"
            v-model="delayTime"
            :rules="{
              required: delayedDelivery ? true : false,
              regex: /^[-+]?[1-9]\d*$/,
            }"
            prepend-icon="fas fa-stopwatch"
            :placeholder="$t('modals.addUser.delayTime')"
          >
          </base-input>
        </div>

        <!-- Reaction Type -->
        <base-input>
          <template slot="label">
            {{ $t("modals.addUser.reactionType") }}
            <i class="fas fa-info-circle" v-b-tooltip.hover.bottom :title="$t('modals.tips.reactionType')"></i>
          </template>
          <el-select
            v-model="reactionType"
            :disabled="!isAvailable || addingUser"
            :placeholder="$t('modals.addUser.reactionType')"
          >
            <el-option
              v-for="reaction in reactionTypes"
              :key="reaction.value"
              :label="reaction.label"
              :value="reaction.value"
            >
            </el-option>
          </el-select>
        </base-input>

        <!-- Tweet Limit -->
        <b-row class="mt-3">
          <b-col cols="12">
            <span class="range-slider-value">
              {{ `${$t("modals.addUser.tweetLimit")}: ${parseInt(tweetLimit)}` }}
            </span>
          </b-col>
          <b-col cols="12">
            <base-slider
              :disabled="!isAvailable || addingUser"
              v-model="tweetLimit"
              :options="{ step: 1 }"
              :range="{ min: 1, max: currentPlan.availableTweet }"
            ></base-slider>
          </b-col>
        </b-row>

        <!-- Favorite Limit -->
        <b-row class="mt-3">
          <b-col cols="12">
            <span class="range-slider-value">
              {{ `${$t("modals.addUser.favoriteLimit")}: ${parseInt(favoriteLimit)}` }}
            </span>
          </b-col>
          <b-col cols="12">
            <base-slider
              :disabled="!isAvailable || addingUser"
              v-model="favoriteLimit"
              :options="{ step: 1 }"
              :range="{ min: 1, max: currentPlan.availableFav }"
            ></base-slider>
          </b-col>
        </b-row>

        <!-- Button -->
        <div class="text-center">
          <base-button
            :disabled="!isAvailable || addingUser"
            :loading="addingUser"
            block
            type="primary"
            native-type="submit"
            class="my-4"
          >
            {{ $t("modals.addUser.addUserButton") }}
          </base-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import BaseSlider from "../../components/BaseSlider.vue";
import { Select, Option } from "element-ui";
import { mapState } from "vuex";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseSlider,
  },
  computed: {
    ...mapState("currentPlan", ["currentPlan"]),
    isAvailable() {
      if (this.currentPlan) return this.currentPlan.tweetLimit > 0 && this.currentPlan.favLimit > 0;
      else return false;
    },
    reactionTypes() {
      return [
        {
          label: this.$t("reactionTypes.random"),
          value: 1,
        },
        {
          label: this.$t("reactionTypes.hmm"),
          value: 2,
        },
        {
          label: this.$t("reactionTypes.sad"),
          value: 3,
        },
        {
          label: this.$t("reactionTypes.haha"),
          value: 4,
        },
        {
          label: this.$t("reactionTypes.cheer"),
          value: 5,
        },
        {
          label: this.$t("reactionTypes.like"),
          value: 6,
        },
      ];
    },
  },
  data: () => ({
    addingUser: false,
    username: "",
    includeRetweets: false,
    includeComments: false,
    naturalDelivery: false,
    delayedDelivery: false,
    delayTime: "",
    reactionType: 1,
    tweetLimit: 1,
    favoriteLimit: 1,
  }),
  methods: {
    async onSubmit() {
      this.addingUser = true;
      const response = await this.$store.dispatch("currentPlanUser/addUser", {
        username: this.username,
        includeRetweets: this.includeRetweets,
        includeComments: this.includeComments,
        naturalSend: this.naturalDelivery,
        delay: this.delayedDelivery ? parseInt(this.delayTime) : 0,
        reactionType: this.reactionType,
        favLimit: parseInt(this.favoriteLimit),
        tweetLimit: parseInt(this.tweetLimit),
      });
      this.addingUser = false;
      if (response) {
        this.$bvModal.hide("addUserModal");
        this.clearData();
        this.showSuccessAlert();
      }
    },
    clearData() {
      this.username = "";
      this.includeRetweets = false;
      this.includeComments = false;
      this.naturalDelivery = false;
      this.delayedDelivery = false;
      this.delayTime = "";
      this.reactionType = 1;
      this.tweetLimit = 1;
      this.favoriteLimit = 1;
    },
    showSuccessAlert() {
      swal.fire({
        title: `<strong>${this.$t("modals.addUser.addSuccess")}</strong>`,
        showConfirmButton: false,
        icon: "success",
        timer: 3000,
        timerProgressBar: true,
      });
    },
  },
};
</script>

<style></style>
