<template>
  <div>
    <add-user-modal />
    <edit-user-modal />
    <base-header class="pb-6">
      <!-- Current User -->
      <b-row align-v="center" class="py-4">
        <b-col cols="12">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ `${$t("pages.dashboard.activeUser")}: ` }}
            <!-- If User Has a Plan -->
            <template v-if="hasPlan == true">
              <span v-if="currentUser != null">
                {{ Object.keys(currentUser).length > 0 ? `@${currentUser.username}` : "-" }}
              </span>
              <i v-else class="fas fa-spinner fa-spin text-white"></i>
            </template>
            <!-- If User Does Not Have a Plan -->
            <template v-else>
              <span>-</span>
            </template>
          </h6>
        </b-col>
      </b-row>
      <!-- Current Plan Stats -->
      <current-plan-stats />
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <!-- Current Plan User -->
        <current-plan-users />
        <!-- Current User Details -->
        <current-user-details />
        <!-- Current User Usages -->
        <current-user-usages />
      </b-row>
    </b-container>
  </div>
</template>
<script>
import CurrentUserDetails from "../../Widgets/CurrentUserDetails.vue";
import CurrentPlanStats from "../../Widgets/CurrentPlanStats.vue";
import CurrentPlanUsers from "../../Widgets/CurrentPlanUsers.vue";
import CurrentUserUsages from "../../Widgets/CurrentUserUsages.vue";
import AddUserModal from "../../Modals/AddUser.vue";
import EditUserModal from "../../Modals/EditUser.vue";

import { mapGetters, mapState } from "vuex";
export default {
  metaInfo() {
    return {
      title: this.$t("routerTitles.dashboard"),
    };
  },
  components: {
    CurrentPlanStats,
    CurrentPlanUsers,
    CurrentUserDetails,
    CurrentUserUsages,
    AddUserModal,
    EditUserModal,
  },
  computed: {
    ...mapGetters("currentPlan", ["currentUser"]),
    ...mapState("user", ["hasPlan"]),
  },
};
</script>
<style>
.list-group-flush .list-group-item:last-child {
  border-bottom-width: 0;
}

.card-stats {
  min-height: 120px;
}

.details-placeholder-card {
  padding: 0px !important;
}

.details-card {
  height: 420px !important;
}
</style>
