<template>
  <b-modal
    id="ExplicitConsentTextModal"
    size="lg"
    title="Açık Rıza Metni"
    hide-footer
  >
    <div class="d-block text-center">
      <p class="text-center mb-4">
        <strong>Otofav.org</strong> tarafından hazırlanan Kişisel Verilerin
        İşlenmesi Hakkında Aydınlatma/Bilgilendirme metnini okuduğumu ve
        anladığımı, Kişisel Verilerin İşlenmesi Hakkında
        Aydınlatma/Bilgilendirme metninde detaylı olarak yer alan kişisel
        verilerimin işlenme amaçları, aktarıldığı kurum, kuruluş, şirket ve
        diğer ortakları ile, toplanma yöntemleri ve hukuki sebepleri, kişisel
        verilerimin korunmasına yönelik haklarım, veri güvenliği ve başvuru
        hakkıma dair bilgilendirildiğimi, Kişisel ve Özel Nitelikli verilerimin;
        sözleşmenin ifası, kanunda açıkça öngörülmesi, Otofav.org’nin hukuki
        yükümlülüğünü yerine getirebilmesi için Kişisel Verilerin İşlenmesi
        Hakkında Aydınlatma/Bilgilendirme metninde belirtilen hususlara uygun
        olarak muhafaza edilmesi, işlenmesi ve aktarılmasını,
        <strong>AÇIK RIZAM ile KABUL EDİYORUM.</strong>
      </p>
      <p class="text-center mb-4">
        <strong>Otofav.org</strong> ile kurduğum ilişki nedeniyle elde
        edebileceğim kişisel verileri
        <strong>6698 sayılı Kişisel Verilerin Korunması Hakkında Kanun</strong>
        gereği hukuka uygun olarak ve açık rıza ile elde etmeyi, korumayı,
        işlemeyi, aktarmayı; açık rıza olmadan veya hukuka aykırı olarak elde
        ettiğim kişisel verileri derhal silmeyi ve durumu
        <strong>Otofav.org</strong> Kişisel Veri Sorumlusu Temsilcisi’ne
        bildirmeyi; aksi durumun tespiti halinde verileri ihlal edilenler veya
        Kişisel Verileri Koruma Kurulu ve sair idari ve yargı organlarınca bir
        yaptırım uygulanması ve Otofav.org’nin zararının doğması halinde bu
        zararı gidermeyi, böyle bir durumda aynı zamanda cezai sorumluluğun da
        tarafıma ait olduğunu bildiğimi kabul, beyan ve taahhüt ederim.
      </p>
    </div>
  </b-modal>
</template>

<script>
export default {};
</script>

<style></style>
