<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success pb-4 pb-lg-5 pt-7">
      <b-container>
        <div class="header-body text-center mb-8">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">
                {{ $t("auth.resendVerificationEmail") }}
              </h1>
              <p class="text-lead text-white">
                {{ $t("auth.resendVerificationEmailHeader") }}
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <!-- Resend Verification Form -->
          <b-card
            v-if="!isEmailSent"
            no-body
            class="bg-secondary border-0 mb-0"
          >
            <b-card-header class="bg-transparent">
              <div class="text-muted text-center mt-2 mb-2">
                <strong>{{ $t("auth.resendVerificationEmail") }}</strong>
              </div>
            </b-card-header>
            <b-card-body class="px-lg-5 py-lg-5">
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    :disabled="isLoading"
                    alternative
                    class="mb-3"
                    name="emailAddress"
                    :rules="{ required: true, email: true }"
                    prepend-icon="ni ni-email-83"
                    :placeholder="$t('auth.emailAddress')"
                    v-model="model.emailAddress"
                  >
                  </base-input>

                  <div class="text-center">
                    <base-button
                      :loading="isLoading"
                      block
                      type="primary"
                      native-type="submit"
                      class="my-4"
                    >
                      {{ $t("auth.send") }}
                    </base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>

          <!-- Resend Verification Success Notification -->
          <b-card v-else no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center">
                <div
                  class="far fa-check-circle text-center text-success mb-4"
                  style="font-size: 6rem"
                ></div>

                <p class="text-center text-big mb-4">
                  <span v-html="$t('auth.resendVerificationEmailSuccess')">
                  </span>
                  <small
                    class="text-muted"
                    v-html="$t('auth.resendVerificationEmailHint')"
                  >
                  </small>
                </p>

                <base-button
                  block
                  type="primary"
                  class="my-2"
                  @click="$router.push({ name: 'signIn' })"
                >
                  {{ $t("auth.loginToYourAccount") }}
                </base-button>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: this.$t("routerTitles.auth.resendVerificationEmail"),
    };
  },
  data() {
    return {
      model: {
        emailAddress: "",
      },
      isEmailSent: false,
      isLoading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      const response = await this.$store.dispatch(
        "auth/resendVerificationEmail",
        {
          emailAddress: this.model.emailAddress,
        }
      );
      if (response) this.isEmailSent = true;
      this.isLoading = false;
    },
  },
};
</script>
