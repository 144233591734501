<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success pb-4 pb-lg-5 pt-7">
      <b-container>
        <div class="header-body text-center mb-8">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">
                {{ $t("auth.emailVerification") }}
              </h1>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <!-- Email Verification Success Notification -->
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center">
                <div
                  class="far fa-check-circle text-center text-success mb-4"
                  style="font-size: 6rem"
                ></div>

                <p class="text-center text-big mb-4">
                  <span v-html="$t('auth.emailVerified')"> </span>
                </p>

                <base-button
                  block
                  type="primary"
                  class="my-2"
                  @click="$router.push({ name: 'signIn' })"
                >
                  {{ $t("auth.loginToYourAccount") }}
                </base-button>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: this.$t("routerTitles.auth.verifyEmail"),
    };
  },
};
</script>
