<template>
  <b-row>
    <!-- Personalized Plan -->
    <b-col md="4">
      <stats-card class="bg-gradient-default subs-card">
        <b-row v-if="hasPlan != null && plans != null">
          <b-col>
            <h4 class="card-title text-uppercase text-muted mb-0 text-white">
              {{ $t("widgets.subscriptionActions.customizedPlanTitle") }}
            </h4>
            <p class="mb-0 text-sm">
              <span class="text-light">
                {{ $t("widgets.subscriptionActions.customizedPlanText") }}
              </span>
            </p>
          </b-col>
          <b-col cols="2" md="auto" class="text-center mr-4 mr-sm-0">
            <base-button
              :disabled="!plans[3].isAvailable"
              round
              class="btn-icon-only mr-2"
              @click="$bvModal.show('customPlanModal')"
            >
              <span class="btn-inner--icon">
                <div class="icon icon-shape bg-white text-dark rounded-circle shadow">
                  <i class="fas fa-sliders-h"></i>
                </div>
              </span>
            </base-button>
          </b-col>
        </b-row>
        <b-row v-else>
          <ContentLoader height="50" width="380" secondaryColor="#b8b8b8">
            <circle cx="350" cy="20" r="17" />
            <rect x="15" y="5" rx="2" ry="2" width="110" height="10" />
            <rect x="15" y="25" rx="2" ry="2" width="190" height="10" />
          </ContentLoader>
        </b-row>
      </stats-card>
    </b-col>
    <!-- Subscription Management -->
    <b-col md="4">
      <stats-card class="bg-gradient-default subs-card">
        <!-- Card body -->
        <b-row v-if="hasPlan != null">
          <b-col>
            <h4 class="card-title text-uppercase text-muted mb-0 text-white">
              {{ $t("widgets.subscriptionActions.subscriptionManagementTitle") }}
            </h4>
            <p class="mb-0 text-sm">
              <span class="text-light">
                {{ $t("widgets.subscriptionActions.subscriptionManagementText") }}
              </span>
            </p>
          </b-col>
          <b-col cols="2" md="auto" class="text-center mr-4 mr-sm-0">
            <base-button
              :disabled="!hasPlan || isLeavingPage"
              round
              class="btn-icon-only mr-2"
              @click="manageSubscription"
            >
              <span class="btn-inner--icon">
                <div class="icon icon-shape bg-white text-dark rounded-circle shadow">
                  <i v-if="isLeavingPage" class="fas fa-cog fa-spin"></i>
                  <i v-else class="fas fa-cog"></i>
                </div>
              </span>
            </base-button>
          </b-col>
        </b-row>
        <b-row v-else>
          <ContentLoader height="50" width="380" secondaryColor="#b8b8b8">
            <circle cx="350" cy="20" r="17" />
            <rect x="15" y="5" rx="2" ry="2" width="110" height="10" />
            <rect x="15" y="25" rx="2" ry="2" width="190" height="10" />
          </ContentLoader>
        </b-row>
      </stats-card>
    </b-col>
    <!-- Cancel Subscription -->
    <b-col md="4">
      <stats-card class="bg-gradient-default subs-card">
        <!-- Card body -->
        <b-row v-if="hasPlan != null">
          <b-col>
            <h4 class="card-title text-uppercase text-muted mb-0 text-white">
              {{ $t("widgets.subscriptionActions.subscriptionCancelTitle") }}
            </h4>
            <p class="mb-0 text-sm">
              <span class="text-light">
                {{ $t("widgets.subscriptionActions.subscriptionCancelText") }}
              </span>
            </p>
          </b-col>
          <b-col cols="2" md="auto" class="text-center mr-4 mr-sm-0">
            <base-button :disabled="!hasPlan" round class="btn-icon-only mr-2" @click="cancelSubscription">
              <span class="btn-inner--icon">
                <div class="icon icon-shape bg-white text-dark rounded-circle shadow">
                  <i class="fas fa-trash"></i>
                </div>
              </span>
            </base-button>
          </b-col>
        </b-row>
        <b-row v-else>
          <ContentLoader height="50" width="380" secondaryColor="#b8b8b8">
            <circle cx="350" cy="20" r="17" />
            <rect x="15" y="5" rx="2" ry="2" width="110" height="10" />
            <rect x="15" y="25" rx="2" ry="2" width="190" height="10" />
          </ContentLoader>
        </b-row>
      </stats-card>
    </b-col>
  </b-row>
</template>

<script>
import StatsCard from "@/components/Cards/StatsCard";
import { ContentLoader } from "vue-content-loader";
import { mapState } from "vuex";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  props: ["plans"],
  components: {
    StatsCard,
    ContentLoader,
  },
  computed: {
    ...mapState("user", ["hasPlan"]),
  },
  data: () => ({
    isLeavingPage: false,
  }),
  methods: {
    async manageSubscription() {
      this.isLeavingPage = true;
      const response = await this.$store.dispatch("subscription/manage");
      if (response) window.location = response.data.url;
      this.isLeavingPage = false;
    },
    async cancelSubscription() {
      let vm = this;
      swal
        .fire({
          title: vm.$t("widgets.subscriptionActions.subscriptionCancelConfirmTitle"),
          html: vm.$t("widgets.subscriptionActions.subscriptionCancelConfirmText"),
          padding: "0.8rem",
          width: "40rem",
          focusCancel: true,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#3c4d69",
          confirmButtonText: vm.$t("widgets.subscriptionActions.confirmButton"),
          cancelButtonText: vm.$t("widgets.subscriptionActions.cancelButton"),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // Ask For Another Confirmation
            swal
              .fire({
                title: vm.$t("widgets.subscriptionActions.subscriptionCancelLastConfirmTitle"),
                html: vm.$t("widgets.subscriptionActions.subscriptionCancelLastConfirmText"),
                padding: "0.8rem",
                width: "40rem",
                focusCancel: true,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f5365c",
                cancelButtonColor: "#3c4d69",
                confirmButtonText: `<i class='fas fa-spinner fa-spin'></i><span>&nbsp;${vm.$t(
                  "widgets.subscriptionActions.confirmButton"
                )}</span>`,
                cancelButtonText: vm.$t("widgets.subscriptionActions.cancelButton"),
                onBeforeOpen: () => {
                  swal.getConfirmButton().disabled = true;
                  setTimeout(() => {
                    swal.getConfirmButton().disabled = false;
                    swal.getConfirmButton().innerHTML = vm.$t("widgets.subscriptionActions.confirmButton");
                  }, 5000);
                },
              })
              .then(async (result) => {
                if (result.isConfirmed) {
                  // If Confirmed, Send Cancel Request and Show Loading Alert
                  swal.fire({
                    title: "<i class='fas fa-spinner fa-spin' style='font-size: 2.5rem; margin-top:2rem;'></i>",
                    html: vm.$t("widgets.subscriptionActions.cancellingNotification"),
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onBeforeOpen: async function () {
                      const response = await vm.$store.dispatch("subscription/delete");
                      swal.close();
                      // Show Success Alert If Subscription Cancelled & Redirect User to the Dashboard
                      if (response) {
                        vm.$store.dispatch("user/setUserHasPlan", false);
                        vm.$store.dispatch("currentPlan/setCurrentPlan", null);
                        swal.fire({
                          title: vm.$t("widgets.subscriptionActions.cancellationSuccess"),
                          showConfirmButton: false,
                          icon: "success",
                          timer: 3000,
                          timerProgressBar: true,
                          onDestroy: () => {
                            vm.$router.push({ name: "dashboard" });
                          },
                        });
                      }
                    },
                  });
                }
              });
          }
        });
    },
  },
};
</script>

<style></style>
