<template>
  <div>
    <template v-if="$i18n.locale == 'tr'">
      <CEMPermission />
      <ExplicitConsentText />
    </template>
    <!-- Header -->
    <div class="header bg-gradient-success pb-4 pb-lg-5 pt-7">
      <b-container>
        <div class="header-body text-center mb-8">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">{{ $t("auth.welcome") }}!</h1>
              <p class="text-lead text-white">
                {{ $t("auth.signUpHeader") }}
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="6" md="8">
          <!-- Register Form -->
          <b-card v-if="!isRegistered" no-body class="bg-secondary border-0 mb-0">
            <b-card-header class="bg-transparent">
              <div class="text-muted text-center mt-2 mb-2">
                <strong>{{ $t("auth.signUp") }}</strong>
              </div>
            </b-card-header>
            <b-card-body class="px-lg-5 py-lg-5">
              <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    :disabled="isLoading"
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-circle-08"
                    :placeholder="$t('auth.firstName')"
                    name="firstName"
                    :rules="{
                      required: true,
                      min: 3,
                      regex: /^[a-zA-ZğüçışöĞÜÇİŞÖ]+(?: [a-zA-ZğüçışöĞÜÇİŞÖ]+)*$/,
                    }"
                    v-model="model.firstName"
                  >
                  </base-input>

                  <base-input
                    :disabled="isLoading"
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-circle-08"
                    :placeholder="$t('auth.lastName')"
                    name="lastName"
                    :rules="{
                      required: true,
                      min: 2,
                      regex: /^[a-zA-ZğüçışöĞÜÇİŞÖ]+(?: [a-zA-ZğüçışöĞÜÇİŞÖ]+)*$/,
                    }"
                    v-model="model.lastName"
                  >
                  </base-input>

                  <base-input
                    :disabled="isLoading"
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-email-83"
                    :placeholder="$t('auth.emailAddress')"
                    name="emailAddress"
                    :rules="{ required: true, email: true }"
                    v-model="model.emailAddress"
                    auto-complete="off"
                  >
                  </base-input>

                  <base-input
                    :disabled="isLoading"
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-lock-circle-open"
                    :placeholder="$t('auth.password')"
                    type="password"
                    name="password"
                    :rules="{
                      required: true,
                      min: 6,
                      regex: /^((?=.*?[a-z])(?=.*?[0-9])).{6,32}$/,
                    }"
                    v-model="model.password"
                    autocomplete="new-password"
                  >
                    <template slot="append">
                      <i class="fas fa-info-circle" v-b-tooltip.hover.top :title="$t('auth.passwordHint')"></i>
                    </template>
                  </base-input>
                  <b-row class="my-2">
                    <template v-if="$i18n.locale == 'en'">
                      <b-col cols="12">
                        <base-input :rules="{ required: { allowFalse: false } }" name="privacyCookiePolicy">
                          <b-form-checkbox :disabled="isLoading" v-model="privacyCookiePolicy">
                            <span :class="{ 'disable-links': isLoading }">
                              I agree with the
                              <a href="/privacy-and-cookie-policy" target="_blank">
                                <strong>Privacy and Cookie Policy</strong>
                              </a>
                            </span>
                          </b-form-checkbox>
                        </base-input>
                      </b-col>
                    </template>
                    <template v-if="$i18n.locale == 'tr'">
                      <b-col cols="12">
                        <base-input :rules="{ required: { allowFalse: false } }" name="confirmationEM" inline>
                          <b-form-checkbox :disabled="isLoading" v-model="confirmationEM">
                            <span :class="{ 'disable-links': isLoading }">
                              <a href="/kvkk-aydinlatma-metni" target="_blank"> <strong>Aydınlatma Metni</strong></a
                              >'ni okudum ve kampanyalardan haberdar olmak için
                              <a href="#" v-b-modal.ExplicitConsentTextModal>
                                <strong>Açık Rıza Metni</strong>
                              </a>
                              kapsamında yukarıda ilettiğim iletişim bilgilerime elektronik ileti gönderilmesini kabul
                              ediyorum.
                            </span>
                          </b-form-checkbox>
                        </base-input>
                      </b-col>
                      <b-col cols="12">
                        <base-input :rules="{ required: { allowFalse: false } }" name="confirmationCEM" inline>
                          <b-form-checkbox :disabled="isLoading" v-model="confirmationCEM">
                            <span :class="{ 'disable-links': isLoading }">
                              <a href="#" v-b-modal.CEMPermissionModal> <strong>Ticari Elektronik İleti İzni</strong></a
                              >'ni okudum, onaylıyorum ve ticari iletileri almayı kabul ediyorum.
                            </span>
                          </b-form-checkbox>
                        </base-input>
                      </b-col>
                    </template>
                  </b-row>
                  <div class="text-center">
                    <base-button block native-type="submit" type="primary" class="mt-2" :loading="isLoading">
                      {{ $t("auth.signUp") }}
                    </base-button>
                    <small v-if="$i18n.locale == 'tr'" class="text-center text-sm-left mt-2 form-text text-muted">
                      <span :class="{ 'disable-links': isLoading }">
                        Üye ol butonuna basarak
                        <a href="/gizlilik-ve-cerez-politikasi" target="_blank">
                          <strong>Gizlilik ve Çerez Politikası</strong> </a
                        >'nı, <a href="/kvkk-aydinlatma-metnini" target="_blank"> <strong>Aydınlatma Metni</strong></a
                        >'ni okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz.
                      </span>
                    </small>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>

          <!-- Register Success Notification -->
          <b-card v-else no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center">
                <div class="far fa-check-circle text-center text-success mb-4" style="font-size: 6rem"></div>

                <p class="text-center text-big mb-4">
                  <span v-html="$t('auth.registerSuccess')"> </span>
                  <small class="text-muted" v-html="$t('auth.registerHint')"> </small>
                </p>

                <base-button block type="primary" class="my-2" @click="$router.push({ name: 'signIn' })">
                  {{ $t("auth.loginToYourAccount") }}
                </base-button>
              </div>
            </b-card-body>
          </b-card>
          <b-row v-if="!isRegistered" class="mt-2">
            <b-col cols="6">
              <router-link :to="{ name: 'signIn' }" class="text-light">
                <small>{{ $t("auth.signIn") }}</small>
              </router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import CEMPermission from "../../Modals/CEMPermission.vue";
import ExplicitConsentText from "../../Modals/ExplicitConsentText.vue";
export default {
  metaInfo() {
    return {
      title: this.$t("routerTitles.auth.signUp"),
    };
  },
  components: {
    CEMPermission,
    ExplicitConsentText,
  },
  data() {
    return {
      model: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        password: "",
      },
      confirmationCEM: false,
      confirmationEM: false,
      privacyCookiePolicy: false,
      isLoading: false,
      isRegistered: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      const response = await this.$store.dispatch("auth/signUp", {
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        emailAddress: this.model.emailAddress,
        password: this.model.password,
      });
      if (response) this.isRegistered = true;
      this.isLoading = false;
    },
  },
};
</script>
<style>
.custom-checkbox .custom-control-input ~ .custom-control-label {
  height: auto !important;
}

span.disable-links {
  pointer-events: none !important;
  cursor: default;
}
</style>
