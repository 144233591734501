<template>
  <b-card no-body class="card-profile" img-src="img/theme/img-1-1000x600.jpg" alt="Image placeholder" img-top>
    <b-row class="justify-content-center">
      <b-col lg="3" class="order-lg-2">
        <div class="card-profile-image">
          <b-img src="img/theme/user.png" rounded="circle" />
        </div>
      </b-col>
    </b-row>

    <b-card-header class="text-center border-0 pt-7 pt-md-6 pb-0 pb-md-4"> </b-card-header>

    <b-card-body class="pt-0">
      <div class="text-center">
        <h5 class="h3">{{ `${user.firstName} ${user.lastName}` }}</h5>
        <template v-if="hasPlan != null && plans != null">
          <template v-if="hasPlan && currentPlan != null">
            <div class="h5 font-weight-300">{{ this.planName }} Plan</div>
            <div class="h5 mt-4">
              {{
                `${$t("pages.myProfile.subscriptionStart")} ${$d(new Date(parseInt(currentPlan.createdDate)), "short")}`
              }}
            </div>
            <div class="h5 mt-0">
              {{
                `${$t("pages.myProfile.subscriptionRenew")} ${$d(new Date(parseInt(currentPlan.expiryDate)), "short")}`
              }}
            </div>
          </template>
          <template v-else>
            <div v-if="!hasPlan" class="h5 font-weight-300">{{ $t("pages.myProfile.noSubscription") }}</div>
          </template>
        </template>
        <div class="mt-3">
          <base-button size="sm" @click="$router.push({ name: 'subscription' })">
            {{ $t("pages.myProfile.manageSubscription") }}
          </base-button>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["plans"],
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("currentPlan", ["currentPlan"]),
    ...mapState("user", ["hasPlan"]),
    planName() {
      return this.plans.filter((plan) => this.currentPlan.planId == plan.id)[0].name;
    },
  },
};
</script>
<style></style>
