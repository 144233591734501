<template>
  <b-row>
    <!-- Content -->
    <template v-if="!showLoader">
      <!-- Show If User Does Not Have A Plan -->
      <template v-if="showEmpty">
        <!-- Subscription Status -->
        <b-col md="6" xl="3">
          <stats-card
            :title="$t('widgets.currentPlanStats.subscriptionStatus')"
            type="gradient-info"
            :sub-title="$t('widgets.currentPlanStats.passive')"
            icon="fas fa-credit-card"
            icon-classes="icon-sm"
          >
            <template slot="footer">
              <div v-html="$t('widgets.currentPlanStats.startSubscriptionTip')"></div>
            </template>
          </stats-card>
        </b-col>
        <!-- User Count -->
        <b-col md="6" xl="3">
          <stats-card
            :title="$t('widgets.currentPlanStats.userCount')"
            type="gradient-success"
            sub-title="-"
            icon="fas fa-user"
            icon-classes="icon-sm"
          >
          </stats-card>
        </b-col>
        <!-- Favorite Limit -->
        <b-col md="6" xl="3">
          <stats-card
            :title="$t('widgets.currentPlanStats.favLimit')"
            type="gradient-warning"
            sub-title="-"
            icon="fas fa-heart"
            icon-classes="icon-sm"
          >
          </stats-card>
        </b-col>
        <!-- Tweet Limit -->
        <b-col md="6" xl="3">
          <stats-card
            :title="$t('widgets.currentPlanStats.tweetLimit')"
            type="gradient-danger"
            sub-title="-"
            icon="fab fa-twitter"
            icon-classes="icon-sm"
          >
          </stats-card>
        </b-col>
      </template>
      <!-- Show If User Has A Plan -->
      <template v-else>
        <!-- Subscription Status -->
        <b-col md="6" xl="3">
          <stats-card
            :title="$t('widgets.currentPlanStats.subscriptionStatus')"
            type="gradient-info"
            :sub-title="
              currentPlan.isDisabled ? $t('widgets.currentPlanStats.canceled') : $t('widgets.currentPlanStats.active')
            "
            icon="fas fa-credit-card"
            icon-classes="icon-sm"
          >
            <template slot="footer">
              <span v-html="$t('widgets.currentPlanStats.manageSubscriptionTip')"></span>
            </template>
          </stats-card>
        </b-col>
        <!-- User Count -->
        <b-col md="6" xl="3">
          <stats-card
            :title="$t('widgets.currentPlanStats.userCount')"
            type="gradient-success"
            :sub-title="`${currentPlan.currentPlanUsers ? currentPlan.currentPlanUsers.length : 0}/${
              currentPlan.maxCurrentPlanUser
            }`"
            icon="fas fa-user"
            icon-classes="icon-sm"
          >
            <template slot="footer">
              <base-progress
                class="progress-xs mt--2"
                :type="
                  parseInt(currentPlan.currentPlanUsers ? currentPlan.currentPlanUsers.length : 0) <
                  currentPlan.maxCurrentPlanUser
                    ? 'success'
                    : 'danger'
                "
                :value="
                  (parseInt(currentPlan.currentPlanUsers ? currentPlan.currentPlanUsers.length : 0) /
                    currentPlan.maxCurrentPlanUser) *
                  100
                "
              ></base-progress>
              <div
                v-if="
                  parseInt(currentPlan.currentPlanUsers ? currentPlan.currentPlanUsers.length : 0) <
                  currentPlan.maxCurrentPlanUser
                "
                class="mt-1 text-success"
              >
                {{
                  $t("widgets.currentPlanStats.userCountAvailable", {
                    count:
                      currentPlan.maxCurrentPlanUser -
                      parseInt(currentPlan.currentPlanUsers ? currentPlan.currentPlanUsers.length : 0),
                  })
                }}
              </div>
              <div v-else class="mt-1 text-danger">
                {{ $t("widgets.currentPlanStats.userCountUnavailable") }}
              </div>
            </template>
          </stats-card>
        </b-col>
        <!-- Favorite Limit -->
        <b-col md="6" xl="3">
          <stats-card
            :title="$t('widgets.currentPlanStats.favLimit')"
            type="gradient-warning"
            :sub-title="`${currentPlan.reservedFav}/${currentPlan.favLimit}`"
            icon="fas fa-heart"
            icon-classes="icon-sm"
          >
            <template slot="footer">
              <base-progress
                class="progress-xs mt--2 mb-0"
                :type="currentPlan.availableFav > 0 ? 'success' : 'danger'"
                :value="(currentPlan.reservedFav / currentPlan.favLimit) * 100"
              ></base-progress>
              <div v-if="currentPlan.availableFav > 0" class="mt-1 text-success">
                {{
                  $t("widgets.currentPlanStats.favLimitAvailable", {
                    count: currentPlan.availableFav,
                  })
                }}
              </div>
              <div v-else class="mt-1 text-danger">
                {{ $t("widgets.currentPlanStats.favLimitUnavailable") }}
              </div>
            </template>
          </stats-card>
        </b-col>
        <!-- Tweet Limit -->
        <b-col md="6" xl="3">
          <stats-card
            :title="$t('widgets.currentPlanStats.tweetLimit')"
            type="gradient-danger"
            :sub-title="`${currentPlan.reservedTweet}/${currentPlan.tweetLimit}`"
            icon="fab fa-twitter"
            icon-classes="icon-sm"
          >
            <template slot="footer">
              <base-progress
                class="progress-xs mt--2 mb-0"
                :type="currentPlan.availableTweet > 0 ? 'success' : 'danger'"
                :value="(currentPlan.reservedTweet / currentPlan.tweetLimit) * 100"
              ></base-progress>
              <div v-if="currentPlan.availableTweet > 0" class="mt-1 text-success">
                {{
                  $t("widgets.currentPlanStats.tweetLimitAvailable", {
                    count: currentPlan.availableTweet,
                  })
                }}
              </div>
              <div v-else class="mt-1 text-danger">
                {{ $t("widgets.currentPlanStats.tweetLimitUnavailable") }}
              </div>
            </template>
          </stats-card>
        </b-col>
      </template>
    </template>
    <!-- Placeholder -->
    <template v-else>
      <b-col md="6" xl="3">
        <b-card no-body>
          <b-card-body class="details-placeholder-card">
            <ContentLoader height="120" width="380" secondaryColor="#b8b8b8">
              <circle cx="335" cy="35" r="15" />
              <rect x="20" y="25" rx="2" ry="2" width="190" height="11" />
              <rect x="20" y="45" rx="2" ry="2" width="110" height="11" />
              <rect x="20" y="85" rx="2" ry="2" width="220" height="11" />
            </ContentLoader>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6" xl="3">
        <b-card no-body>
          <b-card-body class="details-placeholder-card">
            <ContentLoader height="120" width="380" secondaryColor="#b8b8b8">
              <circle cx="335" cy="35" r="15" />
              <rect x="20" y="25" rx="2" ry="2" width="190" height="11" />
              <rect x="20" y="45" rx="2" ry="2" width="110" height="11" />
              <rect x="20" y="85" rx="2" ry="2" width="220" height="11" />
            </ContentLoader>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6" xl="3">
        <b-card no-body>
          <b-card-body class="details-placeholder-card">
            <ContentLoader height="120" width="380" secondaryColor="#b8b8b8">
              <circle cx="335" cy="35" r="15" />
              <rect x="20" y="25" rx="2" ry="2" width="190" height="11" />
              <rect x="20" y="45" rx="2" ry="2" width="110" height="11" />
              <rect x="20" y="85" rx="2" ry="2" width="220" height="11" />
            </ContentLoader>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6" xl="3">
        <b-card no-body>
          <b-card-body class="details-placeholder-card">
            <ContentLoader height="120" width="380" secondaryColor="#b8b8b8">
              <circle cx="335" cy="35" r="15" />
              <rect x="20" y="25" rx="2" ry="2" width="190" height="11" />
              <rect x="20" y="45" rx="2" ry="2" width="110" height="11" />
              <rect x="20" y="85" rx="2" ry="2" width="220" height="11" />
            </ContentLoader>
          </b-card-body>
        </b-card>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import StatsCard from "@/components/Cards/StatsCard";
import { mapState } from "vuex";
import { ContentLoader } from "vue-content-loader";
export default {
  components: {
    StatsCard,
    ContentLoader,
  },
  computed: {
    ...mapState("currentPlan", ["currentPlan"]),
    ...mapState("user", ["hasPlan"]),
    showLoader() {
      if (this.hasPlan == null) {
        return true;
      } else {
        if (this.hasPlan && this.currentPlan == null) return true;
        else return false;
      }
    },
    showEmpty() {
      if (!this.hasPlan) return true;
      else return false;
    },
  },
};
</script>

<style>
span.disable-links {
  pointer-events: none !important;
  cursor: default;
}
</style>
