<template>
  <b-col xl="4">
    <!-- Content -->
    <template v-if="!showLoader">
      <!-- If User Does Not Have A Plan -->
      <template v-if="showNoPlan">
        <b-card class="details-card" no-body>
          <!-- Card header -->
          <b-card-header>
            <!-- Title -->
            <b-row align-v="center">
              <b-col>
                <h3 class="mb-0" style="line-height: 1.7">
                  {{ $t("widgets.currentUserUsages.usages") }}
                </h3>
              </b-col>
            </b-row>
          </b-card-header>
          <!-- No User Card Boy -->
          <b-card-body class="currentUserUsagesScrollArea" style="position: relative">
            <div class="text-center mt-4">
              <i class="fas fa-exclamation-circle text-primary display-1"></i>
              <h3 class="text-muted">{{ $t("widgets.general.noPlanInfo") }}</h3>
            </div>
          </b-card-body>
        </b-card>
      </template>
      <!-- If User Has A Plan -->
      <template v-else>
        <b-card class="details-card" no-body>
          <!-- Card header -->
          <b-card-header>
            <!-- Title -->
            <b-row align-v="center">
              <b-col>
                <h3 class="mb-0" style="line-height: 1.7">
                  {{
                    `${$t("widgets.currentUserUsages.usages")} ${
                      Object.keys(currentUser).length > 0 ? `(@${currentUser.username})` : ""
                    }`
                  }}
                </h3>
              </b-col>
            </b-row>
          </b-card-header>
          <!-- Card body -->
          <b-card-body
            v-if="Object.keys(currentUser).length > 0"
            class="currentUserUsagesScrollArea"
            style="position: relative"
          >
            <!-- Current User Usages List -->
            <b-list-group flush class="list my--3">
              <!-- Tweet Limit -->
              <b-list-group-item class="px-0">
                <b-row align-v="center">
                  <!-- Tweet Limit Icon -->
                  <b-col md="auto" style="width: auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-warning">
                      <i class="fas fa-equals"></i>
                    </div>
                  </b-col>
                  <!-- Tweet Limit Content -->
                  <b-col>
                    <h5 style="margin-bottom: 0rem !important">
                      {{ $t("widgets.currentUserUsages.totalTweetLimit") }}
                    </h5>
                    <strong>{{ currentUser.tweetLimit }}</strong>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <!-- Transaction Count -->
              <b-list-group-item class="px-0">
                <b-row align-v="center">
                  <!-- Transaction Count Icon -->
                  <b-col md="auto" style="width: auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-warning">
                      <i class="fas fa-minus"></i>
                    </div>
                  </b-col>
                  <!-- Transaction Count Content-->
                  <b-col>
                    <h5 style="margin-bottom: 0rem !important">
                      {{ $t("widgets.currentUserUsages.transactionCount") }}
                    </h5>
                    <strong>{{ currentUser.tweetLimit - currentUser.remainTweet }}</strong>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <!-- Remaining Tweet -->
              <b-list-group-item class="px-0">
                <b-row align-v="center">
                  <!-- Remaining Tweet Icon -->
                  <b-col md="auto" style="width: auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-warning">
                      <i class="fas fa-plus"></i>
                    </div>
                  </b-col>
                  <!-- Remaining Tweet Content -->
                  <b-col>
                    <h5 style="margin-bottom: 0rem !important">
                      {{ $t("widgets.currentUserUsages.remainingTransactionCount") }}
                    </h5>
                    <strong>{{ currentUser.remainTweet }}</strong>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <!-- Favorite Count  -->
              <b-list-group-item class="px-0">
                <b-row align-v="center">
                  <!-- Favorite Count Icon -->
                  <b-col md="auto" style="width: auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-warning">
                      <i class="fas fa-balance-scale"></i>
                    </div>
                  </b-col>
                  <!-- Favorite Count Content -->
                  <b-col>
                    <h5 style="margin-bottom: 0rem !important">
                      {{ $t("widgets.currentUserUsages.favCountPerTweet") }}
                    </h5>
                    <strong>{{ currentUser.favLimit }}</strong>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
          <!-- No User Card Boy -->
          <b-card-body v-else class="currentUserUsagesScrollArea" style="position: relative">
            <div class="text-center mt-4">
              <i class="fas fa-exclamation-circle text-primary display-1"></i>
              <h3 class="text-muted">{{ $t("widgets.general.noUserInfo") }}</h3>
            </div>
          </b-card-body>
        </b-card>
      </template>
    </template>
    <!-- Placeholder -->
    <template v-else>
      <b-card body-class="details-placeholder-card">
        <ContentLoader height="420" width="516" secondaryColor="#b8b8b8">
          <rect x="30" y="30" rx="2" ry="2" width="190" height="20" />
          <rect x="80" y="94" rx="2" ry="2" width="100" height="10" />
          <circle cx="50" cy="105" r="20" />
          <rect x="80" y="109" rx="2" ry="2" width="80" height="10" />
          <rect x="80" y="179" rx="2" ry="2" width="100" height="10" />
          <circle cx="50" cy="190" r="20" />
          <rect x="80" y="194" rx="2" ry="2" width="80" height="10" />
          <rect x="80" y="264" rx="2" ry="2" width="100" height="10" />
          <circle cx="50" cy="275" r="20" />
          <rect x="80" y="279" rx="2" ry="2" width="80" height="10" />
          <rect x="80" y="349" rx="2" ry="2" width="100" height="10" />
          <circle cx="50" cy="360" r="20" />
          <rect x="80" y="364" rx="2" ry="2" width="80" height="10" />
        </ContentLoader>
      </b-card>
    </template>
  </b-col>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`, { wheelPropagation: false });
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { mapGetters, mapState } from "vuex";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    ContentLoader,
  },
  computed: {
    ...mapGetters("currentPlan", ["currentUser"]),
    ...mapState("user", ["hasPlan"]),
    showLoader() {
      if (this.hasPlan == null) {
        return true;
      } else {
        if (this.hasPlan && this.currentUser == null) return true;
        else return false;
      }
    },
    showNoPlan() {
      if (!this.hasPlan) return true;
      else return false;
    },
  },
  mounted() {
    this.initScrollbar();
  },
  methods: {
    initScrollbar() {
      initScrollbar("currentUserUsagesScrollArea");
    },
  },
};
</script>

<style></style>
