<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success pb-4 pb-lg-5 pt-7">
      <b-container>
        <div class="header-body text-center mb-8">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">{{ $t("auth.resetPassword") }}</h1>
              <p class="text-lead text-white">
                {{ $t("auth.resetPasswordHeader") }}
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <!-- Reset Password Form -->
          <b-card v-if="!isPasswordChanged" no-body class="bg-secondary border-0 mb-0">
            <b-card-header class="bg-transparent">
              <div class="text-muted text-center mt-2 mb-2">
                <strong>{{ $t("auth.resetPassword") }}</strong>
              </div>
            </b-card-header>
            <b-card-body class="px-lg-5 py-lg-5">
              <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    alternative
                    class="mb-3"
                    type="password"
                    prepend-icon="ni ni-lock-circle-open"
                    name="password"
                    ref="newPassword"
                    :rules="{
                      required: true,
                      min: 6,
                      regex: /^((?=.*?[a-z])(?=.*?[0-9])).{6,32}$/,
                    }"
                    :placeholder="$t('auth.newPassword')"
                    v-model="model.password"
                  >
                    <template slot="append">
                      <i class="fas fa-info-circle" v-b-tooltip.hover.top :title="$t('auth.passwordHint')"></i>
                    </template>
                  </base-input>

                  <base-input
                    alternative
                    class="mb-3"
                    type="password"
                    prepend-icon="ni ni-lock-circle-open"
                    name="passwordConfirm"
                    :rules="{
                      required: true,
                      confirmed: 'password',
                    }"
                    :placeholder="$t('auth.newPasswordConfirm')"
                    v-model="model.confirmationPassword"
                  >
                  </base-input>

                  <div class="text-center">
                    <base-button block :loading="isLoading" type="primary" native-type="submit" class="my-4">
                      {{ $t("auth.changePassword") }}
                    </base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>

          <!-- Reset Password Success Notification -->
          <b-card v-else no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center">
                <div class="far fa-check-circle text-center text-success mb-4" style="font-size: 6rem"></div>

                <p class="text-center text-big mb-4">
                  <span v-html="$t('auth.resetPasswordSuccess')"> </span>
                </p>

                <base-button block type="primary" class="my-2" @click="$router.push({ name: 'signIn' })">
                  {{ $t("auth.loginToYourAccount") }}
                </base-button>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: this.$t("routerTitles.auth.resetPassword"),
    };
  },
  data() {
    return {
      model: {
        password: "",
        confirmationPassword: "",
      },
      emailAddress: "",
      token: "",
      isLoading: false,
      isPasswordChanged: false,
    };
  },
  mounted() {
    const queryToken = atob(this.$route.query.token).split(":");
    this.emailAddress = queryToken[0];
    this.token = queryToken[1];
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      const response = await this.$store.dispatch("auth/resetPassword", {
        emailAddress: this.emailAddress,
        token: this.token,
        password: this.model.password,
      });
      if (response) this.isPasswordChanged = true;
      this.isLoading = false;
    },
  },
};
</script>
