<template>
  <b-modal
    id="CEMPermissionModal"
    size="lg"
    title="Ticari Elektronik İleti İzni"
    hide-footer
  >
    <div class="d-block text-center">
      <p class="text-center mb-4">
        <strong>Otofav.org</strong> ile paylaştığım kimlik, iletişim, müşteri
        işlem verilerimin <strong>Otofav.org</strong> hizmetlerinin
        geliştirilmesi ve müşteri memnuniyetinin sağlanması için analiz,
        promosyon, reklam ve kampanya faaliyetlerinin gerçekleştirilmesi
        amacıyla tarafımla iletişime geçilmesi, ticari elektronik ileti
        gönderilmesi ve aynı amaçlarla Otofav.org’un hizmetlerinden faydalandığı
        özel hukuk tüzel kişilerine aktarılmasına açık rızam ve onayım vardır.
        Kampanyalardan haberdar olmak için
        <a href="#" v-b-modal.ExplicitConsentTextModal>
          <strong>Açık Rıza Metni</strong>
        </a>
        kapsamında elektronik ileti almak istiyorum.
      </p>
    </div>
  </b-modal>
</template>

<script>
export default {};
</script>

<style></style>
