<template>
  <b-row>
    <!-- Content -->
    <template v-if="!showLoader">
      <!-- Small Plan -->
      <b-col md="4">
        <b-card no-body class="card-pricing text-center">
          <!-- Plan Header -->
          <b-card-header>
            <h4 class="text-uppercase ls-1 text-primary py-2 mb-0">
              {{ plans[0].name }}
            </h4>
          </b-card-header>
          <!-- Plan Content -->
          <b-card-body>
            <div class="d-inline-flex align-items-center">
              <div class="display-2">
                {{ $t("widgets.subscriptionPlans.price", { price: plans[0].price }) }}
              </div>
              <div class="text-muted">&nbsp;/ {{ $t("widgets.subscriptionPlans.monthly") }}</div>
            </div>
            <ul class="list-unstyled my-4">
              <li>
                <div class="d-inline-flex align-items-center">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                      <i class="fas fa-heart"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2">
                      {{ plans[0].favCount }}
                      {{ $t("widgets.subscriptionPlans.favorites") }}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-inline-flex align-items-center">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                      <i class="fab fa-twitter"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2">
                      {{ plans[0].tweetLimit }}
                      {{ $t("widgets.subscriptionPlans.tweets") }}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-inline-flex align-items-center">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                      <i class="fas fa-user"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2">
                      {{ plans[0].maxCurrentPlanUser }}
                      {{ $t("widgets.subscriptionPlans.users") }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>

            <!-- If User Has a Plan -->
            <template v-if="hasPlan">
              <!-- If Plan is Not Available -->
              <base-button v-if="!plans[0].isAvailable" disabled type="primary" class="mb-3">
                <span v-if="plans[0].id == currentPlan.planId">
                  {{ $t("widgets.subscriptionPlans.currentPlanButton") }}
                </span>
                <span v-else>
                  {{ $t("widgets.subscriptionPlans.comingSoonButton") }}
                </span>
              </base-button>
              <!-- If Plan is Available -->
              <base-button
                v-else
                @click="switchPlan(plans[0])"
                :disabled="plans[0].id == currentPlan.planId"
                type="primary"
                class="mb-3"
              >
                <span v-if="plans[0].id == currentPlan.planId">
                  {{ $t("widgets.subscriptionPlans.currentPlanButton") }}
                </span>
                <span v-else>{{ $t("widgets.subscriptionPlans.switchButton") }}</span>
              </base-button>
            </template>
            <!-- If User Does Not Have a Plan -->
            <template v-else>
              <base-button
                @click="purchasePlan(plans[0])"
                :disabled="!plans[0].isAvailable"
                type="primary"
                class="mb-3"
              >
                <span v-if="!plans[0].isAvailable">
                  {{ $t("widgets.subscriptionPlans.comingSoonButton") }}
                </span>
                <span v-else>{{ $t("widgets.subscriptionPlans.purchaseButton") }}</span>
              </base-button>
            </template>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- Medium Plan -->
      <b-col md="4">
        <b-card no-body class="card-pricing text-center">
          <!-- Plan Header -->
          <b-card-header>
            <h4 class="text-uppercase ls-1 text-primary py-2 mb-0">
              {{ plans[1].name }}
            </h4>
          </b-card-header>
          <!-- Plan Content -->
          <b-card-body>
            <div class="d-inline-flex align-items-center">
              <div class="display-2">
                {{ $t("widgets.subscriptionPlans.price", { price: plans[1].price }) }}
              </div>
              <div class="text-muted">&nbsp;/ {{ $t("widgets.subscriptionPlans.monthly") }}</div>
            </div>
            <ul class="list-unstyled my-4">
              <li>
                <div class="d-inline-flex align-items-center">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                      <i class="fas fa-heart"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2">
                      {{ plans[1].favCount }}
                      {{ $t("widgets.subscriptionPlans.favorites") }}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-inline-flex align-items-center">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                      <i class="fab fa-twitter"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2">{{ plans[1].tweetLimit }} {{ $t("widgets.subscriptionPlans.tweets") }}</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-inline-flex align-items-center">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                      <i class="fas fa-user"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2">
                      {{ plans[1].maxCurrentPlanUser }}
                      {{ $t("widgets.subscriptionPlans.users") }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <!-- If User Has a Plan -->
            <template v-if="hasPlan">
              <!-- If Plan Is Not Available -->
              <base-button v-if="!plans[1].isAvailable" disabled type="primary" class="mb-3">
                <span v-if="plans[1].id == currentPlan.planId">
                  {{ $t("widgets.subscriptionPlans.currentPlanButton") }}
                </span>
                <span v-else>
                  {{ $t("widgets.subscriptionPlans.comingSoonButton") }}
                </span>
              </base-button>
              <!-- If Plan Is Available -->
              <base-button
                v-else
                @click="switchPlan(plans[1])"
                :disabled="plans[1].id == currentPlan.planId"
                type="primary"
                class="mb-3"
              >
                <span v-if="plans[1].id == currentPlan.planId">
                  {{ $t("widgets.subscriptionPlans.currentPlanButton") }}
                </span>
                <span v-else>{{ $t("widgets.subscriptionPlans.switchButton") }}</span>
              </base-button>
            </template>
            <!-- If User Does Not Have a Plan -->
            <template v-else>
              <base-button
                @click="purchasePlan(plans[1])"
                :disabled="!plans[1].isAvailable"
                type="primary"
                class="mb-3"
              >
                <span v-if="!plans[1].isAvailable">
                  {{ $t("widgets.subscriptionPlans.comingSoonButton") }}
                </span>
                <span v-else>{{ $t("widgets.subscriptionPlans.purchaseButton") }}</span>
              </base-button>
            </template>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- Large Plan -->
      <b-col md="4">
        <b-card no-body class="card-pricing text-center">
          <!-- Plan Header -->
          <b-card-header>
            <h4 class="text-uppercase ls-1 text-primary py-2 mb-0">
              {{ plans[2].name }}
            </h4>
          </b-card-header>

          <!-- Plan Content -->
          <b-card-body>
            <div class="d-inline-flex align-items-center">
              <div class="display-2">
                {{ $t("widgets.subscriptionPlans.price", { price: plans[2].price }) }}
              </div>
              <div class="text-muted">&nbsp;/ {{ $t("widgets.subscriptionPlans.monthly") }}</div>
            </div>
            <ul class="list-unstyled my-4">
              <li>
                <div class="d-inline-flex align-items-center">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                      <i class="fas fa-heart"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2">
                      {{ plans[2].favCount }}
                      {{ $t("widgets.subscriptionPlans.favorites") }}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-inline-flex align-items-center">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                      <i class="fab fa-twitter"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2">
                      {{ plans[2].tweetLimit }}
                      {{ $t("widgets.subscriptionPlans.tweets") }}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-inline-flex align-items-center">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white mb-1">
                      <i class="fas fa-user"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2">
                      {{ plans[2].maxCurrentPlanUser }}
                      {{ $t("widgets.subscriptionPlans.users") }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>

            <!-- If User Has a Plan -->
            <template v-if="hasPlan">
              <!-- If Plan Is Not Available -->
              <base-button v-if="!plans[2].isAvailable" disabled type="primary" class="mb-3">
                <span v-if="plans[2].id == currentPlan.planId">
                  {{ $t("widgets.subscriptionPlans.currentPlanButton") }}
                </span>
                <span v-else>
                  {{ $t("widgets.subscriptionPlans.comingSoonButton") }}
                </span>
              </base-button>
              <!-- If Plan Is Available -->
              <base-button
                v-else
                @click="switchPlan(plans[2])"
                :disabled="plans[2].id == currentPlan.planId"
                type="primary"
                class="mb-3"
              >
                <span v-if="plans[2].id == currentPlan.planId">
                  {{ $t("widgets.subscriptionPlans.currentPlanButton") }}
                </span>
                <span v-else>{{ $t("widgets.subscriptionPlans.switchButton") }}</span>
              </base-button>
            </template>
            <!-- If User Does Not Have a Plan -->
            <template v-else>
              <base-button
                @click="purchasePlan(plans[2])"
                :disabled="!plans[2].isAvailable"
                type="primary"
                class="mb-3"
              >
                <span v-if="!plans[2].isAvailable">
                  {{ $t("widgets.subscriptionPlans.comingSoonButton") }}
                </span>
                <span v-else>{{ $t("widgets.subscriptionPlans.purchaseButton") }}</span>
              </base-button>
            </template>
          </b-card-body>
        </b-card>
      </b-col>
    </template>
    <!-- Placeholders -->
    <template v-else>
      <b-col md="4">
        <b-card body-class="plans-placeholder-card">
          <ContentLoader height="440" width="516" secondaryColor="#b8b8b8">
            <rect x="215" y="40" rx="2" ry="2" width="100" height="20" />
            <rect x="235" y="200" rx="2" ry="2" width="90" height="15" />
            <circle cx="212" cy="207" r="12" />
            <rect x="200" y="345" rx="2" ry="2" width="130" height="30" />
            <rect x="235" y="245" rx="2" ry="2" width="90" height="15" />
            <circle cx="212" cy="252" r="12" />
            <rect x="235" y="290" rx="2" ry="2" width="90" height="15" />
            <circle cx="212" cy="297" r="12" />
            <rect x="192" y="110" rx="2" ry="2" width="150" height="50" />
          </ContentLoader>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card body-class="plans-placeholder-card">
          <ContentLoader height="440" width="516" secondaryColor="#b8b8b8">
            <rect x="215" y="40" rx="2" ry="2" width="100" height="20" />
            <rect x="235" y="200" rx="2" ry="2" width="90" height="15" />
            <circle cx="212" cy="207" r="12" />
            <rect x="200" y="345" rx="2" ry="2" width="130" height="30" />
            <rect x="235" y="245" rx="2" ry="2" width="90" height="15" />
            <circle cx="212" cy="252" r="12" />
            <rect x="235" y="290" rx="2" ry="2" width="90" height="15" />
            <circle cx="212" cy="297" r="12" />
            <rect x="192" y="110" rx="2" ry="2" width="150" height="50" />
          </ContentLoader>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card body-class="plans-placeholder-card">
          <ContentLoader height="440" width="516" secondaryColor="#b8b8b8">
            <rect x="215" y="40" rx="2" ry="2" width="100" height="20" />
            <rect x="235" y="200" rx="2" ry="2" width="90" height="15" />
            <circle cx="212" cy="207" r="12" />
            <rect x="200" y="345" rx="2" ry="2" width="130" height="30" />
            <rect x="235" y="245" rx="2" ry="2" width="90" height="15" />
            <circle cx="212" cy="252" r="12" />
            <rect x="235" y="290" rx="2" ry="2" width="90" height="15" />
            <circle cx="212" cy="297" r="12" />
            <rect x="192" y="110" rx="2" ry="2" width="150" height="50" />
          </ContentLoader>
        </b-card>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import { ContentLoader } from "vue-content-loader";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  props: ["plans"],
  components: {
    ContentLoader,
  },
  computed: {
    ...mapState("currentPlan", ["currentPlan"]),
    ...mapState("user", ["hasPlan"]),
    showLoader() {
      if (this.plans == null || this.hasPlan == null) {
        return true;
      } else {
        if (this.hasPlan && this.currentPlan == null) return true;
        else return false;
      }
    },
  },
  methods: {
    async switchPlan(plan) {
      let vm = this;
      // Ask For Plan Change Confirmation
      swal
        .fire({
          title: vm.$t("widgets.subscriptionPlans.switchConfirmTitle"),
          html: vm.$t("widgets.subscriptionPlans.switchConfirmText", { price: plan.price, plan: plan.name }),
          padding: "0.8rem",
          width: "40rem",
          focusCancel: true,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#3c4d69",
          confirmButtonText: vm.$t("widgets.subscriptionPlans.confirmButton"),
          cancelButtonText: vm.$t("widgets.subscriptionPlans.cancelButton"),
        })
        // If Confirmed, Send Request and Show Loading Alert
        .then(async (result) => {
          if (result.isConfirmed) {
            swal.fire({
              title: "<i class='fas fa-spinner fa-spin' style='font-size: 2.5rem; margin-top:2rem;'></i>",
              html: vm.$t("widgets.subscriptionPlans.switchingNotification"),
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onBeforeOpen: async function () {
                const response = await vm.$store.dispatch("subscription/upgrade", { priceId: plan.priceId });
                swal.close();
                // Show Success Alert If Plan Updated
                if (response) {
                  vm.$store.dispatch("currentPlan/setCurrentPlan", response.data.currentPlan);
                  swal.fire({
                    title: vm.$t("widgets.subscriptionPlans.switchSuccess"),
                    showConfirmButton: false,
                    icon: "success",
                    timer: 3000,
                    timerProgressBar: true,
                    onDestroy: () => {
                      let element = document.getElementById("subscriptionNotification");
                      element.scrollIntoView({ behavior: "smooth", block: "center" });
                    },
                  });
                }
              },
            });
          }
        });
    },
    async purchasePlan(plan) {
      let vm = this;
      // Ask For Plan Purchase Confirmation
      swal
        .fire({
          title: vm.$t("widgets.subscriptionPlans.purchaseConfirmTitle"),
          html: vm.$t("widgets.subscriptionPlans.purchaseConfirmText", { price: plan.price, plan: plan.name }),
          padding: "0.8rem",
          width: "40rem",
          focusCancel: true,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#3c4d69",
          confirmButtonText: vm.$t("widgets.subscriptionPlans.confirmButton"),
          cancelButtonText: vm.$t("widgets.subscriptionPlans.cancelButton"),
        })
        // If Confirmed, Send Request and Show Loading Alert
        .then(async (result) => {
          if (result.isConfirmed) {
            swal.fire({
              title: "<i class='fas fa-spinner fa-spin' style='font-size: 2.5rem; margin-top:2rem;'></i>",
              html: vm.$t("widgets.subscriptionPlans.redirectingNotification"),
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onBeforeOpen: async function () {
                const response = await vm.$store.dispatch("subscription/checkout", { priceId: plan.priceId });
                // Redirect to Checkout Page
                if (response) window.location = response.data.url;
                else swal.close();
              },
            });
          }
        });
    },
  },
};
</script>

<style>
.plans-placeholder-card {
  padding: 0px !important;
}
</style>
