<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success pb-4 pb-lg-5 pt-7">
      <b-container>
        <div class="header-body text-center mb-8">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">{{ $t("auth.welcome") }}!</h1>
              <p class="text-lead text-white">
                {{ $t("auth.signInHeader") }}
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-header class="bg-transparent">
              <div class="text-muted text-center mt-2 mb-2">
                <strong>{{ $t("auth.signIn") }}</strong>
              </div>
            </b-card-header>
            <b-card-body class="px-lg-5 py-lg-5">
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    :disabled="isLoading"
                    alternative
                    class="mb-3"
                    name="emailAddress"
                    :rules="{ required: true, email: true }"
                    prepend-icon="ni ni-email-83"
                    :placeholder="$t('auth.emailAddress')"
                    v-model="model.emailAddress"
                  >
                  </base-input>

                  <base-input
                    :disabled="isLoading"
                    alternative
                    class="mb-3"
                    name="password"
                    :rules="{ required: true, min: 6 }"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    :placeholder="$t('auth.password')"
                    v-model="model.password"
                  >
                  </base-input>

                  <div class="text-center">
                    <base-button
                      :loading="isLoading"
                      block
                      type="primary"
                      native-type="submit"
                      class="my-4"
                    >
                      {{ $t("auth.signIn") }}
                    </base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <b-row class="mt-2">
            <b-col cols="6">
              <router-link :to="{ name: 'forgotPassword' }" class="text-light">
                <small>{{ $t("auth.forgotPassword") }}</small>
              </router-link>
            </b-col>
            <b-col cols="6" class="text-right">
              <router-link :to="{ name: 'signUp' }" class="text-light">
                <small>{{ $t("auth.createNewAccount") }}</small>
              </router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: this.$t("routerTitles.auth.signIn"),
    };
  },
  data() {
    return {
      isLoading: false,
      model: {
        emailAddress: "",
        password: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      // this will be called only after form is valid.
      this.isLoading = true;
      await this.$store.dispatch("auth/signIn", {
        emailAddress: this.model.emailAddress,
        password: this.model.password,
      });
      this.isLoading = false;
    },
  },
};
</script>
